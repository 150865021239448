import CryptoJS from 'crypto-js';
const secretKey='LOGINS_PARAM_KEY';
export default {
  // 加密
  encrypt(word) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  },
  // 解密
  decrypt(word) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
}