<template>
  <div class="note-book" v-show="show">
    <div class="mask-wrap">
      <button class="close-btn" @click="close">我已了解，关闭</button>
      <h2>填写指引</h2>
      
      <ul class="steps">
        <li 
          v-for="(item, key) in arr" 
          :key="key" 
          v-html="item"
          :style="{opacity: step == key? 1: 0}">
        </li>
      </ul>
      <div class="step-btn">
        <button :class="['next-btn', step == 0 && 'disabled']" @click="handlePrev">上一个</button>
        <div class="page">({{step+1+'/'+arr.length}})</div>
        <button :class="['next-btn', step == arr.length - 1 && 'finished']" @click="handleNext">{{step == arr.length - 1? '完成': '下一个'}}</button>
      </div>
    </div>

    <div class="mg"></div>
    <div class="center">
      <div class="side" >
        <div class="place" :style="{opacity: 1}"></div>
        <div class="tit1" :style="{
          opacity: step == 0 || step == 1? .1: 1
        }"></div>
        <div class="tit2" :style="{
          opacity: step == 2? .1: 1
        }"></div>
        <div class="tit3" :style="{
          opacity: step == 3? .1: 1
        }"></div>
        <div class="tit4"></div>
        <div class="tit5" :style="{
          opacity: step == 10? 1: 0
        }"></div>
        <div class="tit6" :style="{
          opacity: step == 11? 1: 0
        }"></div>
      </div>
      <div class="title" >
        <div class="mg" :style="{flex: 1}"></div>
        <div class="title-box">
          <div class="tit1" :style="{
            opacity: step == 4? .1: 1
          }"></div>
          <div class="tit2" :style="{
            opacity:  step == 5? .1: 1
          }"></div>
          <div class="tit3" :style="{
            opacity: step == 6? .1: 1
          }"></div>
        </div>
      </div>
      <div class="cnt" >
        <div class="tit1" :style="{
          opacity: step == 7? .1: 1
        }"></div>
        <div class="tit2" :style="{
          opacity: step == 8 || step == 9 ? .4: 1
        }"></div>
      </div>
    </div>
    <div class="mg"></div>
</div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  props:{
    type: {
      default: 'hti',
      type: String
    },
  },
  data(){
    return {
      step: 0,
      show: false,
      arr: [
        '点击某一个小类，将提供<i>手风琴</i>功能，即展开当前表单和收缩其它表单',
        '“<b>*</b>信息名称”，意味着信息需要填写才可以提交',
        '点击<span class="little-btn">提交</span>，表单将不可在修改',
        '点击<span class="little-btn">导出为Word</span>，系统为您导出部分表单信息，存储为Word文件',
        '<b>编号</b>，是表单的独一无二标识',
        '填写<span class="little-npt">顾问邮箱</span>，系统为您发送当前表单链接，方便您查看',
        '<i>倒计时</i>，您可以暂停或者重置。当自动保存成功后为您重新计时',
        '点击<i class="little-btn">标题</i>，可以收缩或展开当前表单',
        '点击<span class="little-btn">保存</span>，将当前表单保存到系统中',
        '点击<span class="little-btn">取消</span>，将显示上一次保存的表单数据',
        '<span class="little-btn">保存</span>“<b>*</b>个人信息”表单，将显示“<b>*</b>院校申请”表单',
        '<span class="little-btn">保存</span>“<b>*</b>院校申请”表单，将根据申请的学历和院校显示其它的表单',
      ]
    }
  },
  mounted(){
    document.body.appendChild(this.$el);
  },
  watch: {
    show(val){
      document.body.style.overflow = val? 'hidden': 'auto'
    }
  },
  methods:{
    showNoteBook(){
      let show = localStorage.getItem('showNoteBook')
      show = parseInt(show)
      if(show) {
        if(show >= this.arr.length - 1){
          return;
        }
        this.step = show;
      }
      this.show = true;
      
    },
    close(){
      this.show = false;
      localStorage.setItem('showNoteBook', this.arr.length)
    },
    handleNext(){
      if(this.arr.length - 1 == this.step){
        this.close()
      } else {
        this.step += 1;
        localStorage.setItem('showNoteBook', this.step)
      }
    },
    handlePrev(){
      if(this.step == 0) return;
      this.step -= 1;
      localStorage.setItem('showNoteBook', this.step)
    }
  }
}
</script>
<style lang="scss">
  $maskBgm: rgba(0,0,0,0.6);
  @mixin maskAttr{
    background: $maskBgm;
    transition: opacity .2s;
  }
  
  .note-book{
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .mask-wrap{
      position: absolute;
      width: 452px;
      padding: 24px;
      border-radius: 2px;
      z-index: 1;
      background-color: rgba(255,255,255,1);
      box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
      .close-btn{
        display: block;
        background-color: #f0f0f0;
        color: #666;
        margin-left: auto;
        font-size: 12px;
        padding: 4px 4px;
        cursor: pointer;
        transition: color .25s;
        &:hover{
          color: #000;
        }

      }
      h2{
        margin: 24px 0 38px;
        font-size: 22px;
        text-align: center;
      }
      .steps{
        position: relative;
        height: 58px;
        padding-left: 16px;
        li{
          position: absolute;
          top: 0;
          padding-left: 8px;
          font-size: 14px;
          margin-bottom: 12px;
          transition: opacity .2s;
          list-style: decimal;
          .little-btn{
            font-size: 12px;
            padding: 1px 4px;
            background-color: var(--form-color-primary);
            border-radius: 2px;
            color: #fff;
            margin: 0 2px;
          }
          .little-npt{
            padding: 1px 4px;
            margin: 0 2px;
            font-size: 12px;
            color: #666;
            border: 1px solid #DCDFE6;
            border-radius: 2px;
          }
          i{
            padding: 0 4px 0 2px; 
          }
          b{
            font-weight: bold;
            color: var(--form-color-primary);
          }
        }
      }
      .check-wrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 12px;
        margin-right: -6px;
        .title{
          text-align: center;
          font-size: 12px;
          color: #333;
          margin-bottom: 10px;
          line-height: 1;
          margin-right: 12px;
        }
        .circle{
          border: 1px solid #999;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #fff;
          background-clip: content-box;
          padding: 2px;
          margin: 0 6px 6px;
          &.current{
            border-color: var(--form-color-primary);
          }
          &.checked{
            background-color: var(--form-color-primary);
            border-color: var(--form-color-primary);
          }
        }

      }
      .step-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 36px auto 0;
        .page{
          font-size: 12px;
        }
      }
      .next-btn{
        margin: 0 24px;
        width: 80px;
        height: 32px;
        font-size: 14px;
        cursor: pointer;
        background-color: #f0f0f0;
        color: #333;
        transition: color .25s;
        line-height: 1.12;
        border-radius: 2px;
        &:hover{
          color: var(--form-color-primary);
        }
        &.disabled{
          opacity: .5;
          cursor: no-drop;
          color: #333;
        }
        &.finished{
          background-color: var(--form-color-primary);
          color: #fff;
        }
      }
    }
    .mg{
      flex: 1;
      @include maskAttr;
      height: 100%;
    }
    .center{
      max-width: 1080px;
      width: 100%;
      height: 100%;
      .side{
        float: left;
        border: 14px solid $maskBgm;
        border-top: 0;
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .place {
          height: 54px;
          width: 100%;
          @include maskAttr;
        }
        .tit1{
          width: 100%;
          @include maskAttr;
          height: 80px;
        }
        .tit2{
          height: 48px;
          width: 100%;
          @include maskAttr;
        }
        .tit3{
          height: 50px;
          width: 100%;
          @include maskAttr;
        }
        .tit4{
          flex: 1;
          width: 100%;
          @include maskAttr;
        }
        .tit5{
          position: absolute;
          background-image: url('../../src/assets/step4.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 172px;
          height: 226px;
          left: 0;
          top: 54px;
          z-index: 1;
        }
        .tit6{
          position: absolute;
          background-image: url('../../src/assets/step5.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 670px;
          left: 0;
          top: 54px;
          z-index: 1;
          width: 172px;
        }
      }
      .title{
        margin-left: 200px;
        height: 122px;
        display: flex;
        flex-direction: column;
        .title-box {
          height: 68px;
          display: flex;
          .tit1{
            @include maskAttr;
            height: 100%;
            width: 162px;
          }
          .tit2{
            @include maskAttr;
            height: 100%;
            flex: 1;
          }
          .tit3{
            @include maskAttr;
            height: 100%;
            width: 200px;
            margin-left: auto;
          }
        }
      }
      .cnt{
        margin-left: 200px;
        height: calc(100% - 68px);
        .tit1{
          width: 100%;
          height: 48px;
          @include maskAttr;
        }
        .tit2{
          width: 100%;
          height: calc(100% - 48px);
          @include maskAttr;
        }
      }
    }
  }
  @media screen and(max-width: 768px){
    .note-book{
      .center{
        @include maskAttr;
        .side, .title, .cnt{
          display: none;
        }
      }
    }
  }
</style>