export default {
  stuManagement: {
    // 學生註冊
    studentReg: "註冊申請 Student Registration",
    nameZh: "中文名/全名 Chinese Name or Full Name",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    gender: "性別 Gender",
    female: "女 Female",
    male: "男 Male",
    dateBirth: "出生日期 Date of Birth",
    phoneNumber: "聯繫電話 Contact Number",
    phoneRegion: "區號 Area Code",
    email: "電子郵箱 Email Address",
    emailTips:'【重要:請確保郵箱準確，可以正常接收郵件，否則會影響申請的進度】',
    emailPassword: "電子郵件密碼（選填） Email Password（optional）",
    country: "居住國家或地區 Country or Region of residence",
    state: "省/州/縣 Province/State/County",
    city: "市/區 City/District",
    region: "區 District",
    postcode: "郵政編碼 Postal Code",
    address: "聯繫地址 Contact Address",
    alternativeAddr: "備選聯繫地址 Alternative Contact Address",
    fillApply: "保存並填寫入學申請 Save and fill out the admission application",
    moreEmailTips:' (多個郵箱請用（；+空格）隔開)',
    notStudentEmail:'(非學生電郵 Not Student Email)',
    // 個人信息
    personalInfo: "個人信息 Personal Information",
    title: "稱謂 Title",
    birthday: "生日 Birthday",
    surname: "姓 Surname/Family Name",
    givenName: "名 Given/First Name",
    nationality: "國籍/地區 Country / Region",
    ancestry: "名族血統 Ethnic origin",
    firstLanguage: "第一語言 First Language",
    birthCountry: "出生國家或地區 Country or Region of Birth",
    birthCity: "出生城市 Birth City",
    isBornLive: "是否自出生便在該國家或地區居住 Have you lived in this country or region since birth",
    liveCountry: "居住國家或地區 Country or Region of residence",
    moveToCountryTime: "在什麼時候移居至該國家或地區？ when did you move to your country or region of permanent residence?",
    getPermanentResidentTime: "在什麼時候取得該國家或地區的居留權？ When did you get permanent residence of this country or region?",
    shareLink:'共享連結',
    // 護照信息
    passportInfo: "護照信息 Passport Information",
    isHasPassport: "是否有護照? Do you have a passport?",
    issueCountry: "簽發國家或地區 Countrty or Region of Issue",
    passportIssueDate: "簽發日期 Date of Issued",
    passportIssueExpried: "有效期至 Date of Expired",
    passportNum: "護照號碼 Passport No.",
    // 聯繫負責人
    contactPerson: "聯繫負責人 Contact Person",
    contactNotice: "(建議提供顧問老師手機號碼，以便收取系統推送學生個案進展) ",
    atLeastOne: "（必須填至少一個 At least one）",
    // 背景資料
    backgroundInfo: "背景資料 Further Information",
    destCtry: "目的國家或地區 Destination Country or Region",
    deleteDestCtry: "刪除目的國家或地區 Delete Destination Country or Region",
    isStudiesInCtry: "是否有在申請目的國家或地區學習過？ Have you ever studied in the country or region of application?",
    updatedVisa: "請提供最新簽證 Please provide the latest visa",
    visaIssueDate: "簽證簽發日期 Visa Issuance Date",
    visaType: "簽證類型 Visa Type",
    visaExpiryDate: "簽證有效期至 Visa Expiry Date",
    lengthStudy: "學期時期(整月數) Semester period (number of whole months)",
    addBackgroundInfo: "新增背景資料 Add background information",
    isHasReligion: "有特別的宗教信仰嗎? Are there any special religious beliefs?",
    explainReligion: "請說明宗教信仰 Please explain religious belief",
    isRefusedRegion: "是否被任何國家或地區拒簽過 Whether the visa has been rejected by any country or region",
    fullDetails: "請提供詳細情況 Please provide details",
    specialNeed: "殘疾或者特殊需求 Disability or special needs",
    listSeparately: "請分別列舉出來 Please list them separately",
    isEnglishFirLang: "英語是第一語言嗎？ Is English the first language?",
    isStudiedInstruction: "修讀過英語為授課語言的高中或以上課程嗎？ Have you taken a high school or above course with English as the language of instruction?",
    expectedPayWay: "誰將支付費用？ Who will pay the fee?",
    expectedPayWayTips: "(本人或家長資助的都選自費) (I or my parents funded are all selected fees)",
    selfFund: "自費 Self-funded",
    otherFund: "其他 Other",
    whatIsDestinationCountry: "你的目的國家/地區是? What is your destination country?",
    // 院校申請
    applicationSch: "院校申請 University Application",
    addApplicationSch: "新增課程 New Course",
    course: "課程 Course",
    deleteCourse: "刪除課程 Delete Course",
    school: "學校 Institution",
    courseLevel: "課程等級 Course Level",
    courseCountry: "國家或地區 Country or Region",
    openingTime: "開學日期 Starting Date",
    addCourseAgain: "加申課程 New Course",
    faculty: "院校 Faculty",
    zone: '校區 Campus',
    // 教育背景
    fkAreaRegion: '區域 Region',
    educationType: "教育背景類型 Type of Education Background",
    educationTypeTips: '請先選擇教育背景類型 Please Select Type of Education Background at first!',
    institution: "學校名 Institution",
    institutionAnalysis: "提示：智能識別的學校 Intelligent identification of the school",
    copy: '復製 Copy',
    institutionAddress: "學校詳細地址 School address",
    otherInstitution: "其他學校名 Other Institution",
    instructedInEng: "以英語為教學考試語言？ Using English as the language of teaching and testing?",
    durationSch: "在校時間 Duration",
    isComplated: "是否完成學習 Complete the study or not?",
    isWillComplated: "將來是否會完成 Will it be completed in future?",
    highSchool: "中學 High School",
    higherEducation: '高等教育 Higher Education',
    university: "大學 University",
    delEduBackground: "刪除教育背景 Delete Education Background",
    eduBackground: "教育背景 Educational Background ",
    eduBackgroundFile: "教育背景資料上傳 Upload educational background information",
    education: '教育背景',
    institutionNum: "院校序號（最小號碼為最近者） Institution Number(The smaller number, the more recent)",
    addEduBackground: "添加教育背景 Add educational background",
    diploma: "專科 Diploma",
    associatesDegree: "副學士 Associate Degree",
    bachelor: "本科 Bachelor",
    master: "碩士 Master",
    preMaster: "碩士預科 Pre Master",
    phD: "博士 PhD",
    foundation: "Foundation",
    class: "Class",
    scoreTypeFour: "4分滿分 out of 4 points",
    scoreTypeFourToTwo: "兩位小數點 上限4.2 out of 4.2, retain two decimal places.",
    scoreTypeFourToFive: "兩位小數點 上限4.5 out of 4.5, retain two decimal places.",
    scoreTypeFive: "5分滿分 out of 5 points",
    scoreTypeSeven: "7分滿分 out of 7 points",
    scoreTypeNine: "兩位小數點, 上限9 out of 9, retain two decimal places.",
    percentage: "Percentage Percentage",
    grading: "Grading Grading",
    classFirst: "1st",
    classTwoToOne: "2:1",
    classTwoToTwo: "2:2",
    classThird: "Third Class",
    classOrdnary: "Ordnary",
    twoDecimals: "可保留兩位小數 Can keep two decimal places",
    eduBackgroundNumMsg: '教育背景序號重復 Education Background Number Recurrences: {num}',
    edubgmNumCount:'最多只能保存三個學歷 You can only save up to three degrees',
    inputSubjectScores: '錄入分科成績Input subject scores',
    checkSubjectScores: '查看分科成績check subject scores',
    // 語言成績
    examination: "語言考試成績 Language examination results",
    language: '語言考試成績',
    hasExamination: "是否有留學標化考試(如: 雅思/托福)？ Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    examType: "考試類型 Exam Type",
    toefl: "TOEFL Ibt 托福考試 TOEFL Ibt TOEFL Test",
    ielts: "IELTS 雅思 IELTS",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    pte: "PTE",
    builtInTest: "院校內部測試 School Internal Test",
    listening: "聽 listen",
    reading: "讀 Read",
    writing: "寫 Write",
    speaking: "說 Speaking",
    overall: "總分 Total Score",
    registrationNo: "查詢賬號 Check account number",
    testCenterNum: "測試中心號碼 Test Center Number",
    testDate: "考試日期 Test Date",
    testTakerId: "考生號碼 Candidate Number",
    awardingCtryRegion: "認證國家或地區 Certification Country or Region",
    ifRetake: "是否想重考 Do you want to retake the exam",
    isForUKVI: "是否 UKVI IELTS Whether UKVI IELTS",
    examUserName: "考試用戶名 Exam User Name",
    examUserPwd: "登錄密碼 Login Password",
    score: "分數 Score",
    deleteExamType: "刪除考試類型 Delete Exam Type",
    major: "主修 Major",
    qualification: "學歷 Education",
    gradeCategory: "成績類別 Grade Category",
    scoreValue: "總分/平均分/百分製Total score/Average score/Percentage system",
    inEngAndExam: "以英語為教學和考試語言 English as the language of teaching and examination",
    inEngNotExam: "以英語為教學語言但不包括考試 English is the language of instruction but not including exams",
    inschoolTime: "在校 At school",
    applicationMsg: "請先將前面的內容選擇完整 Please complete the previous content selection first",
    // 專業資格
    profQual: "專業資格 Professional Qualification",
    qualifications: '專業資格',
    addProfQual: "添加專業資格 Add professional qualification",
    qualName: "資格名稱 Qualification Name",
    qualGroup: "認證機構 Certification Agency",
    fkStudentAppCtry: "認證國家或地區 Certified Country or Region",
    qualGrade: "評分級別(如適用) Grading level (if applicable)",
    qualStartDate: "資格課程開始日期 Qualification Course Start Date",
    qualGetDate: "取得資格日期 Qualification Date",
    qualGetStatus: "專業資格狀態 Professional Qualification Status",
    inProgress: "進行中 In Progress",
    obtained: "已獲得 Obtained",
    deleteProfQual: "刪除專業資格 Delete Professional Qualification",
    // 推薦人
    referee: "推薦人 Refereer",
    recommend: '推薦人',
    addReferee: "添加推薦人 Add referrer",
    instituCompany: "院校/公司 Institution/Company",
    position: "職位 Position",
    contactAddress1: "聯繫地址一 Contact Address One",
    contactAddress2: "聯繫地址二 Contact Address Two",
    deleteReferee: "刪除推薦人 Delete Referrer",
    mainReferee: '(首選推薦人 Main Refereer)',
    mainRefereeText: '置為首選推薦人',
    refereeNum: '推薦人序號',
    refereeNumMsg: '推荐人序號重復 Referee Number Recurrences: {num}',
    refereeCountry: "院校/公司所在國家或地區 Country or Region of Institution/Company",
    ofLetter: '的推薦信',
    recommendationLetter: '推薦信 Letter of recommendation',
    uploadLetter: "上傳推薦信 Upload recommendation",
    letterName: "推薦信名稱 recommendation",
    letterMatchCourse: '推薦信適用課程 Recommended courses',
    inputLetterName: '請輸入推薦信名稱 Please input name of recommendation',
    updateLetterName: '修改推薦信名',
    specifyCourses: '指定課程',
    specifyCoursesTitle: '指定推荐信使用课程',
    specifyCoursesTip: '*如推荐信适用于所有课程，则无需设置，默认适用所有',
    saveSetting: '保存設置',
    beCurrent: '通用',
    // 工作經驗
    experience: "工作經驗 Work Experience",
    workExperience: '工作經驗',
    addWorkExperience: "添加工作經驗 Add work experience",
    workTime: "在職",
    workStartTime: "在職開始日期 Start date of Employment",
    workEndTime: "在職結束日期 End date of Employment",
    duties: "職責 Duties",
    countryOranisation: "單位所在國家或地區 Country or Region of Organisationd",
    stateOranisation: "單位所在省/州/縣 Province/State/County of Organisationd",
    cityOranisation: "單位所在城市/區 City/District of Organisation/Region",
    companyName: "單位名稱 Name of Organisation",
    isOnDuty: "目前是否在職 Are you currently employed in this position?",
    workMode: "全職或業餘 Full-time or Part-Time",
    fullTime: "全職 Full Time",
    partTime: "業餘 Part-Time",
    recentWork: "最近的工作 Most recent work",
    workExperienceNum: '工作經驗(最小號碼為最近工作) Work Experience Number(The smaller number, the more recent)',
    workExperienceNumMsg: '工作經驗序號重復 Work Experience Number Recurrences: {num}',
    // 學生資料上傳
    clickUpload: "上傳",
    clickUploadAgain: '重新上傳',
    stuInfoUpload: "學生資料上傳 Student Information Upload",
    fileData: '學生資料上傳',
    uploadTip: "2M以下 | 格式: PDF",
    uploadLimit: "上傳文件只能是PDF格式  The uploaded file can only be in PDF format",
    uploadSize: "上傳文件不能超過{limit}M  The uploaded file cannot exceed {limit}M",
    uploadDesc: "上傳說明 Upload description:",
    file: "文件 File",
    downloadAllFile: "打包並下載全部文件 Package and download all files",
    downloadAllFileNav:"打包並下載全部文件",
    fileIdentification: "上傳資料識別信息",
    fileIdentificationForIELTS: "上傳識別雅思成績",
    fileIdentificationForHKDSE: "上傳識別HKDSE",
    fileIdentificationForReferee: "上傳識別推薦信",
    studentInformation: "學生資料 Student information",
    plsUploadFile: "請上傳文件 Please upload the file",
    download: "下載",
    downloadTemplate: "下載模板",
    // 後續安排
    isNeedAccommodation: "你是否需要安排住宿？ Do you need to arrange accommodation?",
    isNeedPickUp: "你是否需要安排接機？ Do you need to arrange pick-up?",
    accommodationType: "如需要學校安排住宿請提供你的選擇 If you need the school to arrange accommodation, please provide your choice",
    homestay: "寄宿家庭 Homestay",
    universityAccmmodation: "學校宿舍 University Accommodation",
    offCampusAccmmodation: "校外宿舍 Off Campus Accommodation",
    FollowUpArrangements: "後續安排 Follow-up Arrangements",
    arrangements: '後續安排',
    isAccommodationSmoke: "你是否抽煙 Do you smoke",
    isAccommodationPet: "你是否能接受有寵物的家庭？ Do you want to live in a home with pets ?",
    isAccommodationChildren: "你是否能接受有小孩的家庭？ Do you want to live in a home with children ?",
    isAccommodationFood: "你對食物是否有特殊要求 例如: 素食 Do you need any special food(eg vegetarian) ?",
    accommodationFoodNote: "請詳細說明 Details",
    isAccommodationOther: "你是否有其他要求？ Do you have other requirements ?",
    accommodationOtherNote: "其他要求說明 Other requirements",
    // 父母的聯繫方式
    fatherLastName: "父親的姓 Father's Last Name",
    fatherFirstName: "父親的名 Father's First Name",
    fatherEmail: "父親的郵箱地址 Father's Email Address",
    fatherPhoneNumber: "父親的聯繫電話 Father's Contact Number",
    monterLastName: "母親的姓 Mother's Last Name",
    monterFirstName: "母親的名 Mother's First Name",
    monterEmail: "母親的郵箱地址 Mother's email address",
    monterPhoneNumber: "母親的聯繫電話 Mother's Contact Number",
    parentContactWay: "父母聯繫方式 Parent Contact Way",
    relativesContact: '父母聯繫方式',
    // suitAge18: "適用於申請集團的課程或是18歲以下的學生。 Applicable to applying for group courses or students under the age of 18.",
    jumpOver: "如不適用，請直接進入下一步。 If not applicable, please go directly to the next step.",
    // 導航
    commitApp: "提交申請",
    commit: "提交",
    navStudentReg: "註冊申請",
    navPersonalInfo: "個人信息",
    navBackgroundInfo: "背景資料",
    navApplicationSch: "院校申請",
    // 立即申請
    applyCofirm: "申請確認 Apply for Confirmation",
    applyStatement: "信息表聲明如下: \nThe information table is declared as follows:",
    applyTip: "請勾選以下的方框以確定您已了解完整的申請流程\nPlease check the box below to make sure you understand the complete application process",
    applyBox1: "我已在系統上傳了所有用於申請的支持文件\nI have uploaded all supporting documents for application in the system",
    applyBox2: "我提供的所有學生申請文件都是真實的\nAll the student application documents I provided are true",
    applyBox3: "我同意{platform}平臺使用我所填寫的申請表格資料，及相關申請內容文件（包括但不限於個人陳述、成績單、畢業證書、學位證書、英語考試成績單、推薦信、實習證明、在讀證明、簽證、護照等資料），代表我進行相關入學申請。\nI agree that the {platform} platform can use the application form materials and relevant application documents (including but not limit to Personal statement,  transcript,  Graduation certificate,  Degree certificate,   English test certificate,  Reference letter, Internship certificate, Study certificate, VISA, Passport etc) to apply for admission on my behalf. ",
    applyName: "申請者姓名 Applicant's name:",
    toBack: "返回",
    hadConfirm: "確認",
    nextStep: '下一步 Next',
    pleaseSelect: '請選擇 Please select',
    exportForm: '導出为Word'
  },
  // 父母的聯繫方式
  parentContact: {
    fatherLastName: "父親的姓 Father's Last Name",
    fatherFirstName: "父親的名 Father's First Name",
    fatherEmail: "父親的郵箱地址 Father's Email Address",
    fatherPhoneNumber: "父親的聯繫電話 Father's Contact Number",
    monterLastName: "母親的姓 Mother's Last Name",
    monterFirstName: "母親的名 Mother's First Name",
    monterEmail: "母親的郵箱地址 Mother's email address",
    monterPhoneNumber: "母親的聯繫電話 Mother's Contact Number",
    parentContactWay: "父母聯繫方式 Parent Contact Way",
    suitAge18: "適用於申請集團的課程或是18歲以下的學生，或部分學校網申需要緊急聯系人信息的，請提供父母其中一方或雙方信息。\nApplicable to applying for group courses or students under the age of 18, or for some schools applying online who require emergency contact information, please provide information on one or both parents.",
    jumpOver: "如不適用，請直接進入下一步。 If not applicable, please go directly to the next step.",
    fkStudentAppCountryIdParent: "國家或地區 Country or Region",
    fkAreaStateIdParent: "省/州/縣 Province/State/County",
    fkAreaCityIdParent: "市/區 City / District",
    parentContactAddress1: "聯繫地址 Contact Address",
    parentContactAddress2: "備選聯繫地址 Alternative Contact Address",
    parentContactPostcode: "郵政編碼 Postal Code",
  },
  // 個人信息
  personInfo: {
    personalInfo: "個人信息 Personal Information",
    gender: "性別 Gender",
    gender0: "女 Female",
    gender1: "男 Male",
    title: "稱謂 Title",
    title1: "Mr. 先生",
    title2: "Ms 女士",
    title3: "Miss 小姐",
    title4: "Mrs 夫人",
    birthday: "生日 Birthday",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    fkStudentAppCountryId: "學生國籍/地區 Student Country/Region",
    fkStudentAppAncestryId: "學生種族 Student Race",
    fkStudentAppMotherLanguageId: "學生母語 Student's native language",
    fkStudentAppCountryIdBirth: "出生國家或地區 Country or Region of Birth",
    cityBirth: "出生城市 Birth City",
    isLivedSinceBirth: "是否自出生便在這個國家或地區居住？Have you lived in this country or region since birth?",
    isLivedSinceBirth0: "否 No",
    isLivedSinceBirth1: "是 Yes",
    fkStudentAppCountryIdLive: "目前所在國家/地區 Current country/region",
    stuPhoneNumber: '學生聯繫電話 Student Contact Number',

    migrationTime: "移居時間 Migration Time",
    stayingRightTime: "取居留權時間 Take the right of abode time",
    isHavePassport: "是否有護照 Do you have a passport",
    isHavePassport0: "否 No",
    isHavePassport1: "是 Yes",
    fkStudentAppCountryIdPassport: "簽發國家或地區 Issuing Country or Region",
    passportIssueDate: "簽發日期 Issue Date",
    passportIssueExpried: "有效期至 Valid until",
    passportNumber: "護照號碼 Passport Number",
    followUpPhoneCode: "區號 Area Code",
    followUpPhoneNumber1: "跟進聯繫人電話號碼1 Follow up the contact's phone number 1",
    followUpPhoneCode1: "區號1 Area Code 1",
    followUpEmail1: "跟進人電郵1 Follow Up Email 1",
    followUpPhoneNumber2: "跟進聯繫人電話號碼2 Follow up the contact's phone number 2",
    followUpPhoneCode2: "區號2 Area Code 2",
    followUpEmail2: "跟進人電郵2 Follow Up Email 2",
    followUpPhoneNumber3: "跟進聯繫人電話號碼3 Follow up the contact's phone number 3",
    followUpPhoneCode3: "區號3 Area Code 3",
    followUpEmail3: "跟進人電郵3 Follow Up Email 3",
    passportInfo: "護照信息 Passport Information",
    contactPerson: "聯繫負責人 Contact Person in Charge",
    contactNotice: "(建議提供顧問老師手機號碼，以便收取系統推送學生個案進展)",
    atLeastOne: "(至少填寫一個) (Fill in at least one)",
  },
  registerInfo: {
    nameZh: "中文名/全名 Chinese Name or Full Name",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    gender: "性別 Gender",
    gender0: "女 Female",
    gender1: "男 Male",
    birthday: "出生日期 Date of Birth",
    phoneNumber: "聯繫電話 Telephone Number for Correspondence",
    phoneRegion: "區號 Area Code",
    email: "電子郵箱 Email",
    emailPassword: "電子郵件密碼 Email Password",
    fkStudentAppCountryIdFrom: "居住國家或地區 Country or Region of residence",
    fkAreaStateIdFrom: "省/州/縣 Province/State/County",
    fkAreaCityIdFrom: "市/區 City / District",
    fkAreaCotuDovosopmIdFrom: "區 Area",
    contactPostcode: "郵政編碼 Postal Code",
    contactAddress1: "聯繫地址 Contact Address",
    contactAddress2: "備選聯繫地址 Alternative contact address",
  },
  backgroundInfo: {
    backgroundInfo: "背景資料 Further Information",
    updatedVisa: "請提供最新簽證 Please provide the latest visa",
    deleteDestCtry: "刪除目的國家或地區 Delete Destination Country or Region",
    whoPayTips: "本人或家長支付的都選擇自費",
    // 多組
    fkAreaCountryId: "目的國家或地區 Destination Country or Region",
    isStudyInThisCountry: "你之前是否在申請目的國學習過？Have you ever studied in the destination country?",
    isStudyInThisCountry0: "是 Yes",
    isStudyInThisCountry1: "否 No",
    visaIssueDate: "簽證簽發日期 Visa Issuance Date",
    visaType: "簽證類型 Visa Type",
    visaExpiryDate: "簽證過期日期 Visa Expiry Date",
    studyLength: "學習長度(整月數) Length of study (in whole months)",
    // 固定
    isHaveReligion: "你有特別的宗教信仰嗎？Willing to explain your religion?",
    isHaveReligion0: "否 No",
    isHaveReligion1: "是 Yes",
    religionNote: "宗教信仰說明 Religious Belief Description",
    isVisaRefused: "你是否被任何國家或地區拒簽過？Have you ever been refused a visa application to any country or Region?",
    isVisaRefused0: "否 No",
    isVisaRefused1: "是 Yes",
    visaRefusedNote: "拒簽說明 Visa Refused Note",
    isDisability: "殘疾或者特殊需求 Disability/Special Needs",
    isDisability0: "否 No",
    isDisability1: "是 Yes",
    disabilityNote: "殘疾需求說明 Description of Disability Needs",
    isEnglishMotherLanguage: "英語是你的第一語言嗎？Is English your first language?",
    isEnglishMotherLanguage0: "否 No",
    isEnglishMotherLanguage1: "是 Yes",
    isStudyInEnglish: "你修讀過英語為授課語言的高中或以上課程嗎？Have you studied at secondary or tertiary levels with English as the language of instruction?",
    isStudyInEnglish0: "否 No",
    isStudyInEnglish1: "是 Yes",
    whoPay: "誰將支付你的費用？Who is expected to pay your fees?",
    whoPay0: "自費 Self-fund",
    whoPay1: "其他 Other",
    payNote: "支付說明 Payment Instructions",
  },
  languageInfo: {
    examination: "語言考試成績 Language examination results",
    hasExamination: "是否有留學標化考試(如: 雅思/托福)？ Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    deleteExamType: "刪除考試類型 Delete Exam Type",
    addExamType: "新增考試類型 Add Exam Type",
    toefl: "TOEFL Ibt 托福考試",
    ielts: "IELTS 雅思",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    duolingo: "Duolingo",
    pte: "PTE",
    builtInTest: "院校內部測試 School Internal Test",
    examType: "考試類型 Exam Type",
    examListen: "聽 Listening",
    examRead: "讀 Reading",
    examSpeak: "說 Speaking",
    examWrite: "寫 Writing",
    examTotal: "總分 Overall",
    examScore: '分數 Score',
    registrationNo: '查詢賬號 Registration No.',
    examinationCheckAccount: "考試查詢賬戶 Examination Check Account",
    examinationCheckPassword: "考試查詢密碼 Examination Check Password",
    examinationDate: "考試日期 Test Date",
    examinationLogin: "考試用戶名 Login",
    examinationPin: "登錄密碼 Pin",
    examinationNumber: "考生號碼 Candidate id number",
    testTakerId: '考生號碼 Test Taker ID',
    fkStudentAppCountryId: "認證國家或地區 Awarding Country or Region",
    testCenterNumber: "測試中心號碼 Test Center Number",
    ukviNumber: "UKVI號碼 UKVI Number",
    isUkviIelts: "是否UKVI IELTS? It is UKVI IELTS",
    isUkviIelts0: "否 No",
    isUkviIelts1: "是 Yes",
    isReexamination: "是否想重考 If Retake?",
    isReexamination0: "否 No",
    isReexamination1: "是 Yes",
    reexaminationDate: "重考日期 Reexamination Date",
  },
  qualificationInfo: {
    qualificationInfo: "專業資格 Professional Qualification",
    addQualf: "添加專業資格 Add Professional Qualification",
    deleteQualf: "刪除專業資格 Delete Professional Qualification",
    qualificationName: "資格名稱 Qualification Name",
    qualificationGroup: "認證機構 Certification Agency",
    fkStudentAppCountryId: "資格認證的國家或地區 Country or Region of Qualification Certification",
    qualificationGrade: "評分級別(如適用) Grading level (if applicable)",
    qualificationStartDate: "資格課程開始日期 Qualification Course Start Date",
    qualificationGetDate: "取得資格日期/預計取得資格日期 Qualification Date/Estimated Qualification Date",
    qualificationGetStatus: "專業資格狀態 Professional Qualification Status",
    qualificationGetStatus0: "進行中 In Progress",
    qualificationGetStatus1: "已獲得 Acquired",
  },
  common: {
    saveTemporal: "信息待完善，暫存",
    save: "保存",
    cancel: "取消",
    search: "搜索",
    reset: "重置",
    opera: "操作 Operation",
    download: "文件下載",
    yes: "是 Yes",
    no: "否 No",
    has: "有 Yes",
    hasNot: "沒有 No",
    confirm: "確定",
    want: "想 Yes",
    wantN: "不想 No",
    isNot: "不是",
    startDate: "開始日期(yyyy-mm-dd)",
    endDate: "結束日期(yyyy-mm-dd)",
    tip: "提示",
    del: "刪除",
    undecided: "未決定",
    saveSucc: "保存成功",
    saveFail: "保存失敗",
    submitFail: "提交失敗",
    until: "至",
    update: "編輯",
    deleteSucc: "刪除成功",
    deleteFail: "刪除失敗",
    updateSucc: "編輯成功",
    updateFail: "編輯失敗",
    delete: "刪除",
    uploadFail: "上傳失敗 Upload failed",
    downloadFail: "下載失敗 Download failed",
    systemErr: "系統錯誤 System error",
    pleaseSave: "請先保存{sth}, Please save first",
    filename: '文件名稱',
    filetype: '文件類型',
    noUpload: '未上傳',
    fileUpload: '文件上傳',
    autoSaveStart: '系統正在為您自動保存表單',
    autoSaveOver: '開始為您重新計時保存',
    noSelectTips: '選擇不到可進行手寫輸入 No matching selection, please input the name',
    // 智能识别
    intelligentIdentificationStudentInfo: '智能識別學生資料',
    intelligentIdentificationTips: '通過OCR & NLP識別技術，提取圖像、文檔中的關鍵信息，簡化資料錄入工作',
    uploadPassportNational: '上傳其他地區護照圖片',
    uploadPassport: '上傳中國大陸護照圖片',
    uploadBackground: '上傳背景資料',
    continuetofill: '繼續填寫',
    stuName: '姓名（中文）',
    stuNameEn: '姓名（英文）',
    stuGender: '性別',
    stuNation: '民族',
    stuBirth: '出生日期',
    stuAddr: '聯系地址',
    stuIdcard: '身份證',
    stuBirthPlace: '出生地',
    stuPassport: '護照號碼',
    stuEduExp: '教育經歷',
    stuWorkExp: '工作經歷',
    notUpload: '未上傳',
    branchSubjectType: '請選擇分科類型',
    branchSubjectValue: '請選擇或輸入分數',
    followRemark: '跟進備註 Follow up remarks',
    saveAndSubmit: '保存並提交申請',
    saveAndSubmitTips: '當前已修改了國家/地區，請點擊保存或暫存按鈕',
    submitTime: '提交時間',
    studentList:'學生清單',
    checkRemark:'查看修改備註',
  },
  validate: {
    currentHasEdit: '目前有正在編輯的表單，請完成新增或編輯，點選儲存後，再進行其他操作。 There is a form being edited. Please complete adding or editing, click Save, and then perform other operations.',
    noFormDataTip: '請完善資料 Please complete the information',
    cancelEdit: '請確定取消編輯嗎？ Are you sure cancel the edit status?',
    requiredField: "必填項 Required Field",
    emailValid: "請輸入正確的郵箱地址 Please enter the correct email address",
    birthdayValid: "請檢查出生日期 Please check the date of birth",
    issueDateValid: "請檢查日期, 簽發日期{issueDate}不能在有效期{expriDate}後 Please check the date, the issue date {issueDate} cannot be after the expiration date {expriDate}",
    limit4: "最多五個 Up to five",
    checkDestCtryNum: "至少選擇1個目的國家或地區 Select at least 1 destination country or region",
    reDestCtryName: "您已經添加了這個國家或地區 You have added this country or region",
    checkDate: "請檢查{name}，開始時間不能在結束時間後 Please check {name}, the start time cannot be after the end time",
    delMsg: "您確定要刪除該{msg}嗎？ Are you sure you want to delete this {msg}?",
    delMsgapp: "您確定要刪除該院校申請吗？Are you sure you want to delete this Student Registration?",
    delMsgBgInfo: "您確定定要刪除該目的國家或地區吗？Are you sure you want to delete this Destination Country or Region?",
    delMsgEduInfo: "您確定定要刪除該背景資料吗？Are you sure you want to delete this Educational Background?",
    delMsgFile: "您確定定要刪除該文件吗？Are you sure you want to delete this File?",
    delMsgLang: "您確定定要刪除該考試成績吗？Are you sure you want to delete this Examination results?",
    delMsgQualInfo: "您确定要删除该專業資格吗？Are you sure you want to delete this Professional Qualification?",
    delMsgReferInfo: "您确定要删除该推薦人吗？Are you sure you want to delete this Refereer?",
    delMsgReferLetterInfo: "您确定要删除该推薦信吗？Are you sure you want to delete this recommendation letter?",
    delMsgWorkInfo: "您确定要删除该工作經驗吗？Are you sure you want to delete this Work Experience?",
    checkIeltsRegstNo: "查詢賬號的長度為17到19位 The length of the query account number is 16 to 19",
    checkIeltsTestCenterNo: "測試中心號碼的長度為5到7位 The length of the test center number is 5 to 7 digits",
    checkIeltsCandNo: "考生號碼的長度為5到20位 The length of the candidate number is 5 to 20 digits",
    checkToeflRegstNo: "查詢賬號的長度為16到19位 The length of the query account is 16 to 19 digits",
    checkToeflTestCenterNo: "測試中心號碼的長度為9到12位 The length of the test center number is 9 to 12 digits",
    checkPteRegstNo: "查詢賬號的長度為9到13位 The length of the query account number is 9 to 13",
    checkPteTestCenterNo: "測試中心號碼的長度為5到9位 The length of the test center number is 5 to 9 digits",
    checkPteTakerID: "考生號碼的長度為9到12位 The length of the candidate number is 9 to 12 digits",
    qualDateValid:
      "請檢查日期, 資格課程開始日期{startDate}不能在取得資格日期{getDate}後 Please check the date, the start date of the qualification course {startDate} cannot be after the qualification date {getDate}",
    checkSubmit: "請確認所有表單均已完成並已提交 Please confirm that all forms have been completed and submitted",
    checkPasswordTime: "護照將要過期，請提供有效護照 The passport is about to expired, please provide a valid passport. ",
    checkeQualificationGetStatus: "已獲得專業資格，請檢查取得資格日期 Professional qualifications have been obtained, please check the qualification date.",
    onlyEnlishInput: "只能輸入英文或拼音 Only input English or Pinyin",
    onlyNumberOrCharInput: "只能輸入數字或字符 Only input numbers or characters",
    studentReg: "请完善或保存註冊申請模塊資料 Student Registration module information is not complete, please confirm",
    personalInfo: "请完善或保存個人申請模塊資料 Personal Information module information is incomplete, please confirm",
    backgroundInfo: "请完善或保存背景資料模塊資料 Further Information module information is incomplete, please confirm",
    applicationSch: "请完善或保存院校申請模塊資料 University Application module information is incomplete, please confirm",
    education: "请完善或保存教育背景模塊資料 Type of Education Background module information is incomplete, please confirm",
    workExperience: "请完善或保存工作經驗模塊資料 Work Experience module information is incomplete, please confirm",
    language: "请完善或保存語言成績模塊資料 Examination results module information is incomplete, please confirm",
    qualifications: "请完善或保存專業資格模塊資料 Professional Qualification module information is incomplete, please confirm",
    recommend: "请完善或保存推薦人模塊資料 Refereer's module information is incomplete, please confirm",
    arrangements: "请完善或保存後續安排模塊資料 Follow-up Arrangements module information is incomplete, please confirm",
    relativesContact: "请完善或保存父母聯系方式模塊資料 Parent Contact Way module information is incomplete, please confirm",
    fileData: "请完善或保存材料上傳模塊資料 Student Information Upload module information is incomplete, please confirm",
    pleaseCheckURL: "鏈接格式請以'https://'或'http://'為開頭 Please start the link format with 'https://' or 'http://'",
    checkExamDate: '請檢查考試日期 Please check the Test Date',
    onlyEnlishAddres: '請將中文地址修改為英文 Please change the Chinese address to English',

    // 个人信息
    checkRgstAddress: '超過45字數限制, 建議避免填上整個地址, 以及請把部分內容填在備選聯繫地址',
    gradeCategoryGpa4: 'GPA 介於1到4之間，可保留兩位小數',
    gradeCategoryGpa4d2: 'GPA(兩位小數點 上限4.2)',
    gradeCategoryGpa4d3: 'GPA(兩位小數點 上限4.3)',
    gradeCategoryGpa4d5: 'GPA(兩位小數點 上限4.5)',
    gradeCategoryGpa5: 'GPA 介於1到5之間，可保留兩位小數',
    gradeCategoryGpa7: 'GPA 介於1到7之間，可保留兩位小數',
    gradeCategoryGpa9: 'GPA(兩位小數點, 上限9)',
    gradeCategoryGpa10: 'GPA(兩位小數點, 上限10)',
    gradeCategoryGpa12: 'GPA(兩位小數點, 上限12)',
    gradeCategoryGpa: 'GPA(兩位小數點)',
    gradeCategoryGre: 'GRE(一位小數點)',
    gradeCategoryGmat: 'GMAT(一位小數點)',
    otherRequirements: 'other requirements(兩位小數點)',
    iBDiploma: 'IB Diploma(兩位整數, 上限45)',
    advancedPlacement: 'Advanced Placement(整數，介於1到5之間)',
    gaoKao: 'GaoKao(China) (整數, 上限100)',
    hKDSEHongKong: 'HKDSE (Hong Kong) (兩位整數)',
    ontarioSSD: 'Ontario Secondary School Diploma (整數, 上限100)',
    sat1: 'SAT1 (USA) (整數，介於400到1600之間)',
    sat2: 'SAT2 (USA) (整數，介於400到1600之間)',
    act: 'ACT (USA) (兩位整數)',
    gradeCategoryPercentage: 'GPA 平均分採用百分制並介於40到100之間, 可保留兩位小數',
    checkUKVI: 'UKVI號碼的長度為19到26位 The range of UKVI Number\'s length is 19~26',
    pleaseInputChinaPost: '中國的郵編是6位 China\'s postal code is 6 digits',
    pleaseInputVietnamPost: '越南的郵編是5位 Vietnam\'s postal code is 5 digits',
    contactPostcodeTaiWanValid: '台灣郵遞區號應該是3-6位！',
    contactPostcodeUKValid: '英國郵遞區號應該是5-8位！',
    pleaseCheckPhoneNumber: '請檢查所在區號的手機號碼 Please check the mobile number of your area code',
    pleaseEnPunctuation: '請不要使用特殊符號，如需使用標點符號，需要使用英文輸入法形式符號 Do not use special symbols. For punctuation, use English input symbols',
    addSaveSuccess: '新增学生成功，当前表单链接将发送给顾问邮箱',
    agentEmailRequire: '請填寫顧問郵箱 Please input email of consultant',
    addApplicationSchFailMso: '表單已提交，不能再申請新課程 The form has been submitted, and no new courses can be applied for',
    
    noNullAndNoChinese: '輸入的內容需不為空且非中文 The input must be non-empty and non-Chinese!',

    stuPhoneNumberValid: '學生手機號不能與跟進人手機號一致',
    stuEmailValid: '學生郵箱不能與跟進人郵箱一致',
    examTotalTips:'總分必須大於0 The total score must be greater than 0',
  },
  applyCourse: {
    quickMatchCourse: '額外補充信息',
    currentSchoolDontOneClick: '當前學校暫無額外補充信息',
    currentSchoolCanOneClick: '當前學校有額外補充信息，請盡快完善左側的表單\nThe current school has additional information,\nplease complete the form on the left as soon as possible',
    currentCourseDontOneClick: '該申請課程暫無需額外補充信息',
    matchResult: '以下為額外補充信息信息結果，\n若用戶認為不合適可重新選擇。',
    courseOfficeLink: '課程網申鏈接',
    courseLink: '課程鏈接',
    quickMatching: '快速匹配中...',
    recoverData: '獲取數據中...',
    noAjustCourse: '沒有合適的課程？',
    changeInputCourse: '切換輸入課程',
    chaneSelectCourse: '切換選擇課程',
    helpYouMatchingTitle: '系統已為您匹配到課程',
    chooseOnForm: '當前為您匹配到{num}個相似的申請課程，請選擇您的課程',
    pleaseSelectAll: '請選完所有的下拉框 Please select all the drop-down boxes',
    pleaseSelectTips: '選擇後，若需要取消選擇下拉菜單右邊有清除按鈕 After selection,if youneed to cancel the selection, there is a clear button on the right side ofthe drop-down menu',
    saveTemporalTips: '確定需要【資訊待完善，暫存】院校申請資料嗎？',
    temporalCourse: '暫存課程',
    saveTemporalTips1:'確定需要【資訊待完善，暫存】個人資料嗎？',
  },
  mso:{
    compelete: '完成',
    apply: '申請',
    pleaseLeaveTime: '請留意各種申請及資料上交時間',
    personalInfo: '個人信息',
    schoolApply: '院校申請',
    academicBackground: '學術工作背景',
    recommend: '推薦人',
    studentUpload: '學生資料上傳',
    arrangements: '後續安排'
  }
};
