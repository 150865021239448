<template>
  <div>
    <title-button-comp
      :mainTitle="t('stuManagement.applicationSch')"
      :buttonName="formStatus() == 3 ? t('stuManagement.addCourseAgain'): t('stuManagement.addApplicationSch')"
      @handleAdd="handleAdd"
      addShow
      v-if="showAddBtn"
      :loading="loading"
    ></title-button-comp>
    <el-form
      size="small"
      :class="isEditing(course.uniqueKey) ?'apply-editing' : 'disabled show-form'"
      ref="applicationForm"
      :model="course"
      label-position="top"
      :unique-key="course.uniqueKey"
      v-for="(course, index) in stuInstCourseFormData.instCourses"
      :key="index"
    >
      <div class="sub-title">
        <span>{{
          t("stuManagement.course") +
          " " +
          (stuInstCourseFormData.instCourses.length - index)
        }}</span>
        <help-tip
          @click="handleHelpTip(index)"
          :ref="`helpTipRef${index}`"
          keyCode="courseSelect"
        ></help-tip>
        {{formStatus() == 3 && course.isAddApp ? `(${t('stuManagement.addCourseAgain')})`:''}}
        <span v-if="course.statusRpa" style="color:red;">{{ t('applyCourse.temporalCourse') }}</span>
          <!-- v-if="formStatus() != 2 && ((formStatus() == 3 && course.isAddApp) || !hideSubmitBtn())" 状态为补件状态，课程仍可编辑-->
        <owl-btn-set
          v-if="((formStatus() == 3 && course.isAddApp) || !hideSubmitBtn() || issueInnerAccount())"
          deleteShow
          @delete="deleteCourse(course, index)"
          :updateShow="!isEditing(course.uniqueKey)"
          @update="editCourse(course)"
        />
      </div>
      <el-row :class="['course-wrap', isEditing(course.uniqueKey) && course.hasOneClick && 'active']">
        <el-col class="course-left" :span="isEditing(course.uniqueKey)? 8: 24" :xs="24" >
          <el-row :gutter="10">
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item
                :label="t('stuManagement.courseCountry')"
                prop="fkAreaCountryId"
                :rules="rules.needFiledRule"
              >
                <owl-select
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.fkAreaCountryId"
                  @change="handleSelectCountry(course, course.uniqueKey, index)"
                  :options="countryOptions"
                  :option-config="{ label: 'name', value: 'id' }"
                  :isMobileCanSearch="isMobileCanSearch"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item
                :label="t('stuManagement.school')"
                prop="fkInstitutionId"
                :rules="rules.needFiledRule"
              >
                <owl-select
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.fkInstitutionId"
                  @change="handleSelectInstitution(course, course.uniqueKey)"
                  :options="institutionOptions[course.uniqueKey]"
                  :option-config="{ label: 'name', value: 'id' }"
                  :isMobileCanSearch="isMobileCanSearch"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col v-if="facultyOptions[course.uniqueKey] && facultyOptions[course.uniqueKey].length" :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item :label="t('stuManagement.faculty')" prop="fkInstitutionFacultyId">
                <owl-select
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.fkInstitutionFacultyId"
                  :options="facultyOptions[course.uniqueKey]"
                  :option-config="{ label: 'name', value: 'id' }"
                  :isMobileCanSearch="isMobileCanSearch"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col v-if="zoneOptions[course.uniqueKey] && zoneOptions[course.uniqueKey].length" :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item :label="t('stuManagement.zone')" prop="fkInstitutionZoneId">
                <owl-select
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.fkInstitutionZoneId"
                  :options="zoneOptions[course.uniqueKey]"
                  :option-config="{ label: 'name', value: 'id' }"
                  :isMobileCanSearch="isMobileCanSearch"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item
                :label="t('stuManagement.courseLevel')"
                prop="fkMajorLevelId"
                :rules="rules.needFiledRule"
              >
                <select-group
                :disabled="!isEditing(course.uniqueKey)"
                v-model="course.fkMajorLevelId"
                @change="handleChangeMajorLevel(course,index)"
                :options="levelOptions"
                :config="{ key: 'groupName', label: 'groupName' }"
                group-key="majorLevelDtoList"
                :group-config="{ value: 'id', label: 'levelName', key: 'id'}"
                ></select-group>
              </el-form-item>
            </el-col>
            <!-- 选择课程 -->
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item
                v-if="
                  courseOptions[course.uniqueKey] &&
                  courseOptions[course.uniqueKey].length
                "
                prop="fkInstitutionCourseId" 
                :rules="rules.needFiledRule"
              >
                <div slot="label" class="custom-course-label">
                  <div class="label">{{t('stuManagement.course')}}</div>
                  <div v-show="isEditing(course.uniqueKey)">
                    <span class="tip">{{t('applyCourse.noAjustCourse')}}</span>
                    <span class="btn" @click="changeCustomeCourseName(course)">{{t('applyCourse.changeInputCourse')}}</span>
                  </div>
                </div>
                <owl-select
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.fkInstitutionCourseId"
                  :options="courseOptions[course.uniqueKey]"
                  :option-config="{ label: 'name', value: 'id' }"
                  needOption
                  @getOption="(option) => getCourseOption(option, course)"
                  @change="courseNameChange(course)"
                  :isMobileCanSearch="isMobileCanSearch"
                ></owl-select>
              </el-form-item>
              <el-form-item
                v-else
                :label="t('stuManagement.course')"
                prop="institutionCourseName"
                :rules="rules.needFiledRule"
              >
                <div slot="label" class="custom-course-label">
                  <div class="label">{{t('stuManagement.course')}}</div>
                  <div v-show="isEditing(course.uniqueKey) && course.hasCourseList">
                    <span class="btn" @click="changeCustomeCourseSelect(course) ">{{t('applyCourse.chaneSelectCourse')}}</span>
                  </div>
                </div>
                <owl-input
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.institutionCourseName"
                ></owl-input>
                <!-- @change="courseNameChange(course)" -->
              </el-form-item>
            </el-col>
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item 
                :label="t('stuManagement.openingTime')" 
                prop="openingTime"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  type="date"
                  :disabled="!isEditing(course.uniqueKey)"
                  v-model="course.openingTime"
                  :pickOptions="{}"
                ></owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="isEditing(course.uniqueKey)? 24: 8" :xs="24">
              <el-form-item
                :label="t('applyCourse.courseLink')"
                prop="institutionCourseWebsite"
                :rules="noNeedLinkForLevel.indexOf(course.fkMajorLevelId)===-1?[rules.urlRule, rules.needFiledRule]:undefined"
              >
                <owl-input
                  v-model="course.institutionCourseWebsite"
                  :disabled="!isEditing(course.uniqueKey)"
                  placeholder="https://"
                  class="website-input"
                >
                  <div 
                    slot="suffix" 
                    class="go-to-btn" 
                    v-show="isEditing(course.uniqueKey) || course.institutionCourseWebsite" 
                    @click="goLink(course.institutionCourseWebsite)">GO</div>
                </owl-input>
              </el-form-item>
            </el-col>
            <!-- 输入类型：0文本输入框/1下拉框/2日期选择框/3数字输入框 -->
            <template v-if="isBmsLogin()">
              <el-col
                :span="isEditing(course.uniqueKey)? 24: 8"
                :xs="24"
                v-for="characterItem in course.item"
                :key="characterItem.id"
              >
                <el-form-item
                  :label="characterItem.title"
                  :rules="characterItem.isRequired ? [rules.needFiledRule] : undefined"
                  :prop="characterItem.fieldName"
                >
                  <owl-input
                    v-if="characterItem.inputType != 1"
                    :type="inputType(characterItem.inputType)"
                    v-model="course[characterItem.fieldName]"
                    :disabled="!isEditing(course.uniqueKey)"
                  ></owl-input>
                  <owl-select
                    v-else
                    :disabled="!isEditing(course.uniqueKey)"
                    v-model="course[characterItem.fieldName]"
                    :options="getNameOption(JSON.parse(characterItem.dataSource))"
                    :option-config="{ label: 'label', value: 'value' }"
                  ></owl-select>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-col>
        <!-- 已选择的链路 -->
        <el-col class="course-link-route" :span="24" v-if="!isEditing(course.uniqueKey) &&((course.infoJson && !('other' in course.infoJson))|| (course.infoJson && Object.keys(course.infoJson).length > 1))">
          <span v-html="t('applyCourse.courseOfficeLink')+'：' + infoJsonToLabel(course.infoJson)"></span>
        </el-col>
        <el-col :span="5" :xs="24" v-show="!isEditing(course.uniqueKey)&&course.modifyRemark">
          <el-button type="primary" size="small" @click="checkRemark(course.modifyRemark)">
            {{t('common.checkRemark')}}
          </el-button>
        </el-col>
        <!-- 一键申请 -->
        <template v-if="isEditing(course.uniqueKey)">
          <el-col :span="6" :xs="24" class="course-center center">
            <div class="arrow-wrap">
              <arrow-icon class="icon"/>
              <span>{{t('applyCourse.quickMatchCourse')}}</span>
            </div>
          </el-col>
          <el-col :span="10" :xs="24" class="course-center center">
            <div class="match-box">
              <div :class="['match-loading', loading && 'loading']">
                <span 
                  v-for="(item, index) in ((course.infoJson && !('other' in course.infoJson))||(course.infoJson && Object.keys(course.infoJson).length > 1)? t('applyCourse.recoverData'): t('applyCourse.quickMatching'))" 
                  :key="index"
                  :style="{
                    'animation-delay': ` ${.2 *index}s`,
                  }"
                >{{item}}</span>
              </div>
              <div class="match-result">
                <template v-if="!course.errorTip">
                  <div class="title">
                    <span class="dot">*</span>{{t('applyCourse.matchResult')}}
                  </div>
                  <div class="level-list">
                    <!-- 单条匹配课程 -->
                    <one-click-form
                      ref="oneClickFormRef"
                      :faculty="faculty"
                      :subject_area="subjectArea"
                      :faculty_cdet="facultyCdet"
                      :cdet_crse_name4="cdetCrseName4"
                      :cdet_crse_name5="cdetCrseName5"
                      :facultyTitleName="facultyTitleName"
                      :subjectAreaTitleName="subjectAreaTitleName"
                      :facultyCdetTitleName="facultyCdetTitleName"
                      :cdetCrseName4TitleName="cdetCrseName4TitleName"
                      :cdetCrseName5TitleName="cdetCrseName5TitleName"
                      :initCourse="initCourse"
                      :initChoose="course.infoJson"
                      @get-web="(website) => course.institutionCourseWebsite = website"
                    />
                  </div>
                  <div class="match-tip">{{t('applyCourse.pleaseSelectAll')}}</div>
                  <div class="match-tip" style="color: red;width: 80%;margin:0 auto;">{{t('applyCourse.pleaseSelectTips')}}</div>
                </template>
                <div class="title" v-else>{{course.errorTip}}</div>
              </div>
            </div>
          </el-col>
        </template>
      </el-row>
      <owl-btn-save
        :loading="loading"
        v-if="isEditing(course.uniqueKey)"
        show-delete
        show-save-temporal
        :show-save-and-submit="course.id !== null && course.id !== ''"
        @delete="cancelEditCourse(course, index)"
        @save="handleSave(course.uniqueKey, index)"
        @saveTemporal="handleSaveTemporal(course, index)"
        @saveAndSubmit="handleSaveAndSubmit(course, index)"
      />
    </el-form>
    <div class="form-no-data-tip" v-if="!stuInstCourseFormData.instCourses.length">
      {{t('validate.noFormDataTip')}}
    </div>
    <!-- 有多条匹配课程时的弹窗 -->
    <choose-one-click 
      :visible.sync="showChooseVisible"   
      :faculty="faculty"
      :subject_area="subjectArea"
      :faculty_cdet="facultyCdet"
      :cdet_crse_name4="cdetCrseName4"
      :cdet_crse_name5="cdetCrseName5"
      :initCourses="matchingCourse"
      @confirm="onConfirmMatch"
    />
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import {
  getIssueMajorLevelListApi,
  getCourseToIssue,
  saveOrUpdateCourse,
  saveOrUpdateTempCourse,
  getInstitutionCharacterItem,
  deleteStudentCourse,
  getCountryToIssue,
  getMatchCourseNameApi,
  getIsStartRobotApi,
  getCourseOnlyMatchApi,
  getInstitutionFaculty,
  getInstitutionZone,
  newGetInstitutionDataToIssue
} from "@/api/common.js";
import SelectGroup from "/packages/components/SelectGroup.vue"
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import HelpTip from "/packages/components/HelpTip.vue";
import ArrowIcon from '/packages/components/svg-icon/ArrowIcon.vue'
import OneClickForm from '../components/one-click-form.vue'
import ChooseOneClick from '../components/choose-one-click.vue'
import * as JSBase64 from 'js-base64';
export default {
  name: "StudentApplication",
  mixins: [mixin, i18n],
  // formStatus 0新建未提交：可编辑可新增，1提交申请：不可编辑与新增，2需要补件：可编辑可新增，3加申院校：只能添加新课程，不能编辑提交已提交课程
  // hideSubmitBtn：this.isDisable || this.formStatus === 1 || this.formStatus === 3;
  inject: ["stuid", "hideSubmitBtn", "formStatus", "isBmsLogin","platformType", "platformLimitList", "issueInnerAccount"],
  components: {
    SelectGroup,
    OwlSelect,
    OwlInput,
    OwlBtnSave,
    OwlBtnSet,
    HelpTip,
    ArrowIcon,
    OneClickForm,
    ChooseOneClick,
  },
  props: {
    // 在线表单回显的code
    onlineFormId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      countryDataArr: [],
      loading: false,
      levelOptions: [],
      courseOptions: {},
      countryOptions: [],
      oneClickLink: {},
      institutionOptions: {},
      facultyOptions:{},
      zoneOptions: {},
      stuInstCourseFormData: {
        instCourses: [
          // {id: null,fkAreaCountryId: "", // 申请国家id
          //   fkInstitutionId: "", // 学校id
          //   fkMajorLevelId: "", // 课程等级id
          //   fkInstitutionCourseId: "", // 课程id
          //   fkStudentId: "", // 学生id
          //   openingTime: ""  // 开学日期
          //    课程官网链路
          //   infoJson: {},  // RPA课程信息 以及 动态表单信息
          //   hasOneClick: false,  // 是否支持一键申请
          //   fkInstitutionFacultyId: "",
          //   fkInstitutionZoneId: "",
          //   errorTip: '',  //如果有值 则没有一键选校
          // },
        ],
      },
      time: '',

      faculty: [],  // 第一级
      facultyTitleName:'',// 第一级标题
      subjectArea: [], // 第二级
      subjectAreaTitleName:'',// 第二级标题
      facultyCdet: [], // 第三级
      facultyCdetTitleName:'',// 第三级标题
      cdetCrseName4: [], // 第四级
      cdetCrseName4TitleName:'',// 第四级标题
      cdetCrseName5: [], // 第五级
      cdetCrseName5TitleName:'',// 第五级标题
      matchingCourse: [], // 匹配出来的课程
      initCourse: null, // 多条匹配课程中-选出的单条匹配课程 或 单条匹配课程
      showChooseVisible: false,
      cancelRequest: null,
      noNeedLinkForLevel:[30,31,32,33],// 不需要输入链接的课程等级id(高中或以下)
    };
  },
  mounted() {
    this.getIssueMajorLevelList();
    this.getCountryData();
    this.loading = true;
  },
  computed: {
    getCurMatchSchId(){
      return this.faculty ? this.faculty.id : '';
    },
    inputType() {
      return (inputType) => {
        switch (inputType) {
          case 0:
            return "input";
          case 3:
            return "number";
          case 2:
            return "date";
        }
      };
    },
    infoJsonToLabel(){
      return (infoJson) => {
        if(!infoJson || (Object.keys(infoJson).length < 2 && 'other' in infoJson)) return '';
        let str = '';
        for (let key in infoJson){
          if (key === 'other') continue;
          if(key == 'faculty' && infoJson[key] && infoJson[key].value){
            str += `${infoJson[key].value}； `
          }
          if(!infoJson[key] || !(infoJson[key] instanceof Array)) continue;
          const value = infoJson[key].filter(item=>item).map(item=> item? item.value: '').join('、');
          if(!value) continue;
          str += `${value}； `
        }
        return str;
      }
    },
    showAddBtn(){
      return (!this.hideSubmitBtn() || this.formStatus() == 3 || this.issueInnerAccount())
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  methods: {
    async getCountryItemDto(newVal) {
      if (newVal && newVal.studentCountryDtos) {
        this.countryDataArr = newVal.studentCountryDtos.map((v) => {
          return {
            id: v.id,
            fkAreaCountryId: v.fkAreaCountryId,
          };
        });
      }
    },
    handleHelpTip(index) {
      this.$refs[`helpTipRef${index}`][0].showTips = true;
    },
    getNameOption(sourceData) {
      return typeof sourceData === "object" ? sourceData : [];
    },
    // 获取一键申请链路
    async canMatchCourseName(course, getMatch = true){
      const {fkInstitutionId, fkInstitutionCourseId, institutionCourseName, fkInstitutionCourseName, uniqueKey, fkMajorLevelId,fkInstitutionFacultyId } = course;
      let courseName = '';
      let courseId = '';
      if(institutionCourseName) {
        courseName = institutionCourseName;
      } else if(fkInstitutionCourseId){
        if (fkInstitutionCourseName) {
          courseName = fkInstitutionCourseName;
          courseId = fkInstitutionCourseId;
        } else {
          const courseItem = this.courseOptions[uniqueKey].find(item=> item.id == fkInstitutionCourseId );
          if(courseItem){
            courseName = courseItem.name;
            courseId = fkInstitutionCourseId;
          }
        }
      }
      if(!courseName) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      };
      if(!fkMajorLevelId) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      }
      let levelOption = null;
      this.levelOptions.find((option => {
        return option.majorLevelDtoList.some(item => {
          if (item.id === fkMajorLevelId) {
            levelOption = item;
            return true;
          }
        })
      }))
      if(!levelOption) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      }
      try {
        const ret = await getMatchCourseNameApi({
          schId: fkInstitutionId,
          courseName, 
          stepName: levelOption.levelName, 
          courseId,
          fkMajorLevelId,
          facultyId:fkInstitutionFacultyId,
          cancelBack: (cancel) =>{
            this.cancelRequest = cancel;
          }
        });
        if( ret.data.code == '1003') {
          // 4 5 1 2 3 匹配
          const {cdetCrseName4, cdetCrseName5, faculty, facultyCdet, subjectArea, matchingCourse,
          cdetCrseName4TitleName, cdetCrseName5TitleName, facultyTitleName, facultyCdetTitleName, subjectAreaTitleName,
          } = ret.data.data;
          course.errorTip = '';
          this.faculty = faculty;
          this.subjectArea = subjectArea;
          this.facultyCdet = facultyCdet;
          this.cdetCrseName4 = cdetCrseName4;
          this.cdetCrseName5 = cdetCrseName5;
          // 标题
          this.facultyTitleName = facultyTitleName;
          this.subjectAreaTitleName = subjectAreaTitleName;
          this.facultyCdetTitleName = facultyCdetTitleName;
          this.cdetCrseName4TitleName = cdetCrseName4TitleName;
          this.cdetCrseName5TitleName = cdetCrseName5TitleName;
          course.hasOneClick = true;
          if(!faculty.length && !subjectArea.length && !facultyCdet.length && !cdetCrseName4.length && !cdetCrseName5.length){
            course.errorTip = this.t('applyCourse.currentCourseDontOneClick')
          }
          if(!getMatch){
            return '';
          }
          // 如果匹配到多个课程
          if(matchingCourse.length > 1) {
            this.matchingCourse = matchingCourse
            this.showChooseVisible = true;
          } else if (matchingCourse.length == 1) {
            this.initCourse = matchingCourse[0]
          }
        }
      } catch (e) {
        course.errorTip = e && e.message? e.message : this.t('applyCourse.currentSchoolDontOneClick')
      }
    },
    // 只获取匹配的课程
    async getCourseOnlyMatch(course){
      const {fkInstitutionId, fkInstitutionCourseId, institutionCourseName, uniqueKey, fkMajorLevelId,fkInstitutionFacultyId} = course;
      let courseName = '';
      let courseId = '';
      if(institutionCourseName) {
        courseName = institutionCourseName;
      } else if(fkInstitutionCourseId){
        const courseItem = this.courseOptions[uniqueKey].find(item=> item.id == fkInstitutionCourseId );
        if(courseItem){
          courseName = courseItem.name;
          courseId = fkInstitutionCourseId;
        }
      }
      if(!courseName) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      };
      if(!fkMajorLevelId) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      }
      let levelOption = null;
      this.levelOptions.find((option => {
        return option.majorLevelDtoList.some(item => {
          if (item.id === fkMajorLevelId) {
            levelOption = item;
            return true;
          }
        })
      }))
      if(!levelOption) {
        course.errorTip = this.t('validate.noFormDataTip');
        return;
      }
      try {
        const ret = await getCourseOnlyMatchApi({
          schId: fkInstitutionId,
          courseName, 
          stepName: levelOption.levelName, 
          courseId,
          facultyId:fkInstitutionFacultyId
        });
        if( ret.data.code == '1003') {
          const {matchingCourse} = ret.data.data;
          course.errorTip = '';
          // 如果匹配到多个课程
          if(matchingCourse.length > 1) {
            this.matchingCourse = matchingCourse
            this.showChooseVisible = true;
          // } else if (matchingCourse.length == 1) {
          //   this.initCourse = matchingCourse[0]
          // }
          } else { // 是否有匹配课程都触发变化
            this.initCourse = matchingCourse[0];
          }
        }
      } catch (e) {
        course.errorTip = e && e.message? e.message : this.t('applyCourse.currentSchoolDontOneClick')
      }
    },
    // 当课程名改变时
    courseNameChange(course){
      if (!course&&!course.hasOneClick) { return; }
      this.loading = true;
      // 清空除other以外的课程链路相关内容
      if (course.infoJson && course.infoJson.other) {
        const other = course.infoJson.other;
        course.infoJson = {};
        course.infoJson.other = other;
      } else {
        course.infoJson = null;
      }
      if(this.cancelRequest){
        this.cancelRequest();
        this.cancelRequest = null;
      }
      // 清空额外补充信息
      this.$refs.oneClickFormRef && this.$refs.oneClickFormRef[0] && this.$refs.oneClickFormRef[0].initialValue()
      this.time && clearTimeout(this.time);
      this.time = setTimeout(async ()=>{
        if(this.faculty && this.faculty.length) {
          await this.getCourseOnlyMatch(course)
        } else {
          await this.canMatchCourseName(course);
        }
        this.loading = false
      },500)
    },
    getCourseOption(option, course) {
      if (!option) {
        return course.institutionCourseWebsite = '';
      }
      if (option.courseWebsite) {
        course.institutionCourseWebsite = option.courseWebsite;
      } else {
        course.institutionCourseWebsite = '';
      }
    },
    // 查看该学校是否支持Robot
    async canIsStartRobotApi(course){
      const ret = await getIsStartRobotApi({
        fkInstitutionId: course.fkInstitutionId,
        fkInstitutionZoneId: course.fkInstitutionZoneId,
        fkInstitutionFacultyId: course.fkInstitutionFacultyId
      });
      course.hasOneClick = ret.data.code == '1003' && ret.data.data ? true : false;
      if(course.hasOneClick){
        course.errorTip = this.t('applyCourse.currentSchoolCanOneClick')
      }
      return course.hasOneClick;
    },
    // 初始化数据
    async setInitData(data, insertType = "push") {
      this.loading = true;
      if(data && data.length){
        const courses = [];
        for await (let item of data) {
          let index;
          let infoJson = item.infoJson ? JSON.parse(item.infoJson) : null;
          const uniqueKey = item.uniqueKey || Date.now;
          const course = {
            id: item.id,
            fkAreaCountryId: this.getIdTypeValue(item.fkAreaCountryId),
            fkInstitutionId: this.getIdTypeValue(item.fkInstitutionId),
            fkMajorLevelId: this.getIdTypeValue(item.fkMajorLevelId),
            infoJson: infoJson,
            fkInstitutionCourseId: this.getIdTypeValue(item.fkInstitutionCourseId),
            fkStudentId: this.stuid(),
            uniqueKey: uniqueKey,
            modifyRemark:item.modifyRemark,
            fkInstitutionCourseName: item.fkInstitutionCourseName || '',
            institutionCourseName: item.fkInstitutionCourseName || item.institutionCourseName || '',
            openingTime: item.openingTime? item.openingTime.split(' ')[0] : "",
            institutionCourseWebsite: item.institutionCourseWebsite || '',
            hasCourseList: false,  // 是否有课程列表
            hasOneClick: false,   // 学校是否可以一键申请
            errorTip: '',
            isAddApp: item.isAddApp,
            fkInstitutionFacultyId: this.getIdTypeValue(item.fkInstitutionFacultyId),
            fkInstitutionZoneId: this.getIdTypeValue(item.fkInstitutionZoneId),
            statusRpa: item.statusRpa,
          };
          // 获取内部人员填写内容（动态表单：bms进入才显示）
          // 回显动态表单内容，如有
          if (infoJson && infoJson.other) {
            await this.getInstitutionCharacterItem(item.fkInstitutionId, item.fkAreaCountryId, course);
            Object.keys(infoJson.other).map(keyName => {
              if (keyName === 'password' && infoJson.other[keyName]) {
                course[keyName] = JSBase64.decode(infoJson.other[keyName]);
              } else {
                course[keyName] = infoJson.other[keyName]
              }
            });
          }
          if (insertType == "push") {
            index = courses.push(course) - 1;
          } else {
            // mso逻辑
            index = 0;
            this.stuInstCourseFormData.instCourses.unshift(course);
          }
          if (item.fkAreaCountryId) {
            await this.getSchoolData(item.fkAreaCountryId, uniqueKey);
          }
          if (item.fkInstitutionId) {
            await this.getFacultyOptions(item.fkInstitutionId, uniqueKey)
            await this.getZoneOptions(item.fkInstitutionId, uniqueKey)
          }
          if (item.fkInstitutionId && item.fkMajorLevelId) {
            await this.getCourseSelect(course);
            if(item.fkInstitutionName && !item.fkInstitutionCourseId){
              this.$set(this.courseOptions, item.uniqueKey, []);
            }
          }
          if (insertType != "push") {
            // mso逻辑
            this.switchEditStatus(uniqueKey);
            this.onFormStatusChanged();
          }
        }
        if (insertType === 'push') {
          this.stuInstCourseFormData.instCourses = courses;
        }
      }
      insertType == "push" && await this.getMsoApplySetData();
      if(this.platformLimitList().includes('get_scholarship')) {
        insertType == "push" && await this.getScholarshipApplyData();
      }
      this.loading = false;
    },
    // 获取居住的国家或地区的数据
    async getCountryData() {
      let countryData = [];
      const { data } = await getCountryToIssue();
      if (data.datas) {
        countryData = data.datas;
      }
      this.countryOptions = countryData;
    },
    // 获取目的国家
    getDestCtry({ type, countryId }) {
      this.$emit("getDestinationCountry", {
        type,
        applyLength: this.stuInstCourseFormData.instCourses.length,
        fkAreaCountryId: countryId,
        fkAreaCountryname: this.getNameFromId(
          this.countryOptions,
          countryId,
          "name",
          "id"
        ),
      });
    },
    updateDestCtry({ oldCountryId, newCountryId }) {
      this.$emit("updateDestinationCountry", {
        oldId: oldCountryId,
        newId: newCountryId,
        newCountryname: this.getNameFromId(
          this.countryOptions,
          newCountryId,
          "name",
          "id"
        ),
      });
    },
    async getSchoolData(countryId, key) {
      this.$set(this.courseOptions, key, []);
      const { data } = await newGetInstitutionDataToIssue(countryId, 2);
      this.$nextTick(() => {
        this.$set(this.institutionOptions, key, data.datas);
      });
    },
    // 专业等级下拉
    async getIssueMajorLevelList() {
      const { data } = await getIssueMajorLevelListApi();
      this.levelOptions = data.datas;
    },
    // 下拉国家
    handleSelectCountry(course, key, index) {
      const country = course.fkAreaCountryId;
      this.$delete(this.institutionOptions, key);
      // 清空后面的数据
      this.stuInstCourseFormData.instCourses[index].fkInstitutionId = "";
      this.stuInstCourseFormData.instCourses[index].fkMajorLevelId = "";
      this.stuInstCourseFormData.instCourses[index].fkInstitutionCourseId = null;
      this.stuInstCourseFormData.instCourses[index].fkInstitutionFacultyId = '';
      this.stuInstCourseFormData.instCourses[index].fkInstitutionZoneId = '';
      this.stuInstCourseFormData.instCourses[index].hasOneClick = false;
      this.stuInstCourseFormData.instCourses[index].errorTip = '';
      if (course.item) {
        // 清空上一次的选项
        for (let item of course.item) {
          this.$delete(course, item.fieldName);
        }
        this.$delete(course, "item");
      }
      this.getSchoolData(country, key);
    },

    // 下拉学校
    async handleSelectInstitution(course, key) {
      const insLabel = this.institutionOptions[key].find(item=>item.id == course.fkInstitutionId)?.name
      const fkInstitutionId = course.fkInstitutionId;
      const fkAreaCountryId = course.fkAreaCountryId;
      course.fkInstitutionName = insLabel;
      if (course.item) {
        // 清空上一次的选项
        for (let item of course.item) {
          this.$delete(course, item.fieldName);
        }
        this.$delete(course, "item");
      }
      course.fkInstitutionCourseId = null;
      course.institutionCourseName = '';
      course.fkInstitutionCourseName = '';
      course.fkInstitutionFacultyId = '';
      course.fkInstitutionZoneId = ''
      if (!fkInstitutionId) {
        this.$set(this.courseOptions, key, []);
        return;
      }

      // 初始化下拉数据
      this.faculty = [];
      this.subjectArea = [];
      this.facultyCdet = [];
      this.cdetCrseName4 = [];
      this.cdetCrseName5 = [];
      this.facultyTitleName = '';
      this.subjectAreaTitleName = '';
      this.facultyCdetTitleName = '';
      this.cdetCrseName4TitleName = '';
      this.cdetCrseName5TitleName = '';

      this.loading = true;
      this.getFacultyOptions(course.fkInstitutionId, key)
      this.getZoneOptions(course.fkInstitutionId, key)

      course.errorTip = this.t('validate.noFormDataTip')
      await this.canIsStartRobotApi(course);
      if(course.fkMajorLevelId) await this.getCourseSelect(course);
      this.loading = false;
      this.getInstitutionCharacterItem(fkInstitutionId, fkAreaCountryId, course)
    },
    async getFacultyOptions(insId, key){
      const {data} = await getInstitutionFaculty(insId)
      if(data.datas && data.datas.length) {
        this.$set(this.facultyOptions, key, data.datas)
      } else {
        this.$set(this.facultyOptions, key, [])
      }
    },
    async getZoneOptions(insId, key){
      const {data} = await getInstitutionZone(insId)
      if(data.datas && data.datas.length) {
        this.$set(this.zoneOptions, key, data.datas)
      } else {
        this.$set(this.zoneOptions, key, [])
      }
    },
    // 获取自动生成的下拉框
    async getInstitutionCharacterItem(inst, ctry, course) {
      const { data } = await getInstitutionCharacterItem(ctry, inst);
      if (data.datas) {
        const appInstCharItemList = data.datas;
        this.$set(course, "item", appInstCharItemList);
        this.$forceUpdate();
      }
    },

    // 获取课程下拉
    async getCourseOptions(inst, level, key) {
      const { data } = await getCourseToIssue(inst, level);
      this.$set(this.courseOptions, key, data.datas);
      return data.datas;
    },
    // 新增
    handleAdd() {
      if(this.hasEdit()) return;
      const uniqueKey = Date.now();
      this.stuInstCourseFormData.instCourses.unshift({
        fkAreaCountryId: "",
        fkInstitutionId: "",
        fkMajorLevelId: "",
        fkInstitutionCourseId: null,
        infoJson: null,
        id: null,
        fkStudentId: this.stuid(),
        uniqueKey,
        institutionCourseName: "", // 手动输入
        fkInstitutionCourseName: '', // 手动选择
        openingTime: "",
        modifyRemark:'',
        statusStep: 1, // 当前课程状态
        isAddApp: this.formStatus() === 3,
        institutionCourseWebsite: '',
        hasOneClick: false, // 一键申请
        hasCourseList: false, // 是否有课程列表
        fkInstitutionFacultyId: '',
        fkInstitutionZoneId: '',
        errorTip: '',
        statusRpa: null,
      });
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged();
    },
    // 删除
    deleteCourse(course, index) {
      if(this.loading) return;
      let countryIds = [];
      this.stuInstCourseFormData.instCourses.forEach((v) => {
        v.fkAreaCountryId == course.fkAreaCountryId &&
          countryIds.push(v.fkAreaCountryId);
      });

      this.$confIssue(this.t("validate.delMsgapp"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async () => {
        // 如果有id删除后台数据
        if(course.id){
          try{
            this.loading = true;
            await deleteStudentCourse(course.id);
            this.loading = false;
          } catch (e){
            this.loading = false;
            this.$msge({
              type: "success",
              message: this.t("common.deleteFail"),
            });
            return;
          } 
        }
        const key = course.uniqueKey;
        this.stuInstCourseFormData.instCourses.splice(index, 1);
        // 当课程数据清除完，改变当前模块所处状态（完成 --> 未完成）
        if (!this.stuInstCourseFormData.instCourses.length&&this.fkPlatformType==='get_issue') {
          this.$root.toolMutations.clearState(this.$root.toolState);
          this.$root.toolMutations.addCompleted(this.$root.toolState, 'personalInfo');
          this.$root.toolMutations.addUncompleted(this.$root.toolState, 'applicationSch');
        }
        this.isEditing(key) && this.switchEditStatus(key) // 如果当前是编辑状态 取消
        this.$delete(this.institutionOptions, key); // 删除当前学校选项
        this.$delete(this.courseOptions, key); // 删除当前课程选项
        this.$msge.success(this.t("common.deleteSucc"));  // 提示
        this.onFormStatusChanged(); // 改变状态
        this.updateEmit()
        
        if(!course.id) return;  
        // 删除配置
        this.$emit("delete-config", course.id);
        if ( countryIds.length < 2) {
          // 小于2条删除目标申请国家
          this.getDestCtry({
            type: "delete",
            countryId: course.fkAreaCountryId,
          });
        }
      });
    },
    // 取消编辑
    cancelEditCourse(course, index){
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        const uniqueKey = course.uniqueKey;
        const backup = this.getEditBackupByKey(uniqueKey)
        this.switchEditStatus(uniqueKey)
        if(backup) {
          if (backup.fkAreaCountryId) {
            await this.getSchoolData(backup.fkAreaCountryId, uniqueKey);
          }
          if (backup.fkInstitutionId) {
            await this.getFacultyOptions(backup.fkInstitutionId, uniqueKey)
            await this.getZoneOptions(backup.fkInstitutionId, uniqueKey)
          }
          if (backup.fkInstitutionCourseId) {
            await this.getCourseSelect(course);
          } else {
            if(backup.institutionCourseName){
              this.$set(this.courseOptions, backup.uniqueKey, []);
            }
          }
          // this.$set(this.stuInstCourseFormData.instCourses, index, backup)
          Object.assign(course, backup);
          const vueForm = this.$refs.applicationForm.find(
            (item) => item.$attrs["unique-key"] == course.uniqueKey
          );
          this.$nextTick(()=>{
            vueForm && vueForm.clearValidate();
          })
        } else {
          this.stuInstCourseFormData.instCourses.splice(index, 1)
        }
        this.onFormStatusChanged(); // 改变状态
      })
    },
    
    async checkFormStatus(formRef) {
      let validate = await new Promise((res)=>{
        if(!this.$refs.applicationForm||!this.$refs.applicationForm.length){
          res(false)
        }
        if (formRef) {
          formRef.validate((val)=>{
            if(!val) res(false)
          })
        } else {
          for(let itemForm of this.$refs.applicationForm) {
            itemForm.validate((val)=>{
              if(!val) res(false)
            })
          }
        }
        res(true)
      })
      let error = this.t('validate.applicationSch');
      if (validate && this.$refs.oneClickFormRef && this.$refs.oneClickFormRef[0]){
        const infoJson = this.$refs.oneClickFormRef[0].getSubmitValue()
        let hasOneClick = true;
        if (infoJson && Object.keys(infoJson).length < 2 && 'other' in infoJson) {
          hasOneClick = false
        }
        if(!hasOneClick) {
          error = this.t('applyCourse.currentSchoolCanOneClick')
          validate = false;
        }
      }
      const isEdit = this.editList.length == 0 && this.stuInstCourseFormData.instCourses.length >= 0
      return { isEdit, validate, error }
    },
    // 课程下拉框切换成自定义输入课程
    changeCustomeCourseName(course){
      course.hasOneClick=false;
      course.fkInstitutionCourseId = '';
      this.courseOptions[course.uniqueKey] = [];
    },
    // 课程输入框切换成自定义输入课程
    changeCustomeCourseSelect(course){
      course.hasOneClick=true;
      course.institutionCourseName = '';
      course.fkInstitutionCourseName = '';
      course.fkInstitutionCourseId = '';
      this.getCourseSelect(course)
    },
    // mso点击立即申请设置item
    async getMsoApplySetData() {
      let data = sessionStorage.getItem("msoApply");
      // data类型: {countryId: string, schoolId?: string, majorLevelId?: string, courseId?: string}
      data = data ? JSON.parse(data) : ''
      if (data && data.countryId) {
        sessionStorage.setItem("msoApply", "");
        if(!this.showAddBtn){
          this.$alrt(this.t("validate.addApplicationSchFailMso"), this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
          return;
        }
        const uniqueKey = Date.now()
        await this.setInitData(
          [
            {
              id: "",
              uniqueKey: uniqueKey,
              fkAreaCountryId: data.countryId,
              fkInstitutionId: data.schoolId || "",
              fkMajorLevelId: data.majorLevelId || "",
              fkInstitutionCourseId: data.courseId || null,
            },
          ],
          "unshift"
        );
        const course = this.stuInstCourseFormData.instCourses.find(item=>item.uniqueKey === uniqueKey)
        let bol = await this.canIsStartRobotApi(course)
        bol && await this.canMatchCourseName(course, false)
        this.$emit("on-mso-add-apply", "applicationSch");
      }
    },
    // 奖学金小助手点击立即申请设置item
    async getScholarshipApplyData() {
      let data = sessionStorage.getItem('scholarshipApplyData');
      // data类型: {countryId: string, schoolId: string, majorLevelId: string, courseId: string, institutionCourseWebsite: string}
      data = data ? JSON.parse(data) : '';
      const uniqueKey = Date.now()
      if (data && data.countryId) {
        sessionStorage.setItem('scholarshipApplyData', '')
        if(!this.showAddBtn){
          this.$alrt(this.t("validate.addApplicationSchFailMso"), this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
          return;
        } 
        await this.getCourseOptions(data.schoolId, data.majorLevelId, uniqueKey);
        const option = this.courseOptions[uniqueKey].find(v=> v.id === data.courseId)
        if(option.courseWebsite) {
          data.institutionCourseWebsite = option.courseWebsite;
        }
        let sholarshipParams = {
        id: "",
        uniqueKey: uniqueKey,
        fkAreaCountryId: data.countryId,
        fkInstitutionId: data.schoolId || "",
        fkMajorLevelId: data.majorLevelId || "",
        fkInstitutionCourseId: data.courseId || null,
        institutionCourseWebsite: data.institutionCourseWebsite || ""
      }
        await this.setInitData([sholarshipParams], "unshift");
        const course = this.stuInstCourseFormData.instCourses.find(item=>item.uniqueKey === uniqueKey)
        let bol = await this.canIsStartRobotApi(course) // 获取智能数据
        bol && await this.canMatchCourseName(course) // 获取智能课程名称
      }
    },
    // 编辑
    async editCourse(course) {
      if(this.hasEdit()) return;
      this.loading= true;
      let bol = await this.canIsStartRobotApi(course)
      bol && await this.canMatchCourseName(course, false)
      this.switchEditStatus(course.uniqueKey, course);
      this.onFormStatusChanged();
      this.loading = false;
      if(!course.fkInstitutionCourseId||course.fkInstitutionCourseId===-1){
        course.hasOneClick=false
      }
    },
    async handleSaveTemporal(instCourse, index) {
      const uniqueKey = instCourse.uniqueKey;
      let data = JSON.parse(JSON.stringify(instCourse));
      try {
        let error = false;
        let validDatas = ['fkAreaCountryId', 'fkInstitutionId', 'fkMajorLevelId'];
        if(data.fkInstitutionCourseId || (!data.fkInstitutionCourseId && this.courseOptions[uniqueKey] && this.courseOptions[uniqueKey].length)) {
          validDatas.push('fkInstitutionCourseId')
        }else {
          validDatas.push('institutionCourseName')
        }
        for await (let item of validDatas){
          const valid = await new Promise(res=>{
            this.$refs.applicationForm[index].validateField(item,(error)=>{
              res(error)
            })
          })
          if(valid) error = true;
        }
        if(error) return;
        let other = data.infoJson ? data.infoJson.other : {}
        // 如果有一键申请
        if(data.hasOneClick && !data.errorTip ){
          // 获取一键申请提交的内容与动态表单内容（如有）
          const infoJson = this.$refs.oneClickFormRef[0].getSubmitValue(true);
          if((infoJson && !('other' in infoJson)) || (infoJson && Object.keys(infoJson).length > 1 && 'other' in infoJson)) {
            const item = data.item;
            if (item && item.length) {
              infoJson.other = {};
              item.map((val) => {
                if (val.fieldName === 'password' && data[val.fieldName]) {
                  infoJson.other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
                } else {
                  infoJson.other[val.fieldName] = data[val.fieldName] || '';
                }
              })
            }
            data.infoJson = JSON.stringify(infoJson);
            // console.log(this.courseOptions[uniqueKey])
            if(data.fkInstitutionCourseId && this.courseOptions[uniqueKey].length) {
              validDatas.push('fkInstitutionCourseId')
              // await this.$alrt(this.t("applyCourse.saveTemporalTips"), "", {
              //   distinguishCancelAndClose: true,
              //   confirmButtonText: this.t("common.confirm"),
              //   cancelButtonText: '',
              // })
            }
          }
        } else {
          // 无一键申请，获取动态表单内容（如有）
          const item = data.item;
          data.infoJson = '';
          if (item && item.length) {
            other = other || {}
            item.map((val) => {
              if (val.fieldName === 'password' && data[val.fieldName]) {
                other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
              } else {
                other[val.fieldName] = data[val.fieldName] || '';
              }
            })
            data.infoJson = JSON.stringify({other});
          }
        }
        await this.$alrt(this.t("applyCourse.saveTemporalTips"), "", {
          distinguishCancelAndClose: true,
          confirmButtonText: this.t("common.confirm"),
          cancelButtonText: '',
        })
        this.loading = true;
        const res = await saveOrUpdateTempCourse(data);
        instCourse.statusRpa = 'TO_BE_ADDED';
        const backup = this.getEditBackupByKey(uniqueKey);
        this.switchEditStatus(uniqueKey);
        if (!instCourse.id) {
          instCourse.id = res.data.data;
          data.id = res.data.data;
        }
        const instCourses = this.stuInstCourseFormData.instCourses
        instCourse.infoJson = data.infoJson ? JSON.parse(data.infoJson) : '';
        if(backup){
          // 如果更新后选择的学校国家没有了，需要删除目标国家
          if(
            backup.fkAreaCountryId && 
            instCourses.findIndex(item=> item.fkAreaCountryId == backup.fkAreaCountryId) < 0
          ){
            this.updateDestCtry({
              oldCountryId: backup.fkAreaCountryId,
              newCountryId: data.fkAreaCountryId
            });
          } else if (instCourses.filter(item=> item.fkAreaCountryId == data.fkAreaCountryId).length === 1 && data.fkAreaCountryId != backup.fkAreaCountryId) {
            this.getDestCtry({
              type: "add",
              countryId: data.fkAreaCountryId,
            });
          }
        } else {
          this.getDestCtry({
            type: "add",
            countryId: data.fkAreaCountryId,
          });
        }
        // 处理其他与课程相关数据
        // let fkInstitutionName = '';
        // const fkInstitution = this.institutionOptions[uniqueKey].find(jtem=> jtem.id == data.fkInstitutionId)
        // fkInstitution && (fkInstitutionName = fkInstitution.name)
        // let institutionCourse = '';
        // if(data.fkInstitutionCourseId) {
        //   const institutionCourseName = this.courseOptions[uniqueKey].find(jtem=> jtem.id == data.fkInstitutionCourseId);
        //   institutionCourse = institutionCourseName? institutionCourseName.name: data.institutionCourseName
        // } else {
        //   institutionCourse = data.institutionCourseName
        // }
        if (instCourses.length === 1) {
          this.addCompletedStatus();
        }
        this.$emit('update-apply'); // 重新获取数据
        this.onFormStatusChanged();
        this.$msge({
          message: this.t("common.saveSucc"),
          type: "success",
        });
      } catch{}
      this.loading = false;
    },
    async handleSaveAndSubmit(instCourse, index) {
      const uniqueKey = instCourse.uniqueKey;
      let data = JSON.parse(JSON.stringify(instCourse));
      const backup = this.getEditBackupByKey(uniqueKey);
      if (backup && backup.fkAreaCountryId !== instCourse.fkAreaCountryId) {
        this.$msge({
          message: this.t("common.saveAndSubmitTips"),
          type: "error",
          offset:80
        });
        return;
      }
      try {
        let error = false;
        let validDatas = ['fkAreaCountryId', 'fkInstitutionId', 'fkMajorLevelId', 'openingTime','institutionCourseWebsite'];
        if(data.fkInstitutionCourseId || (!data.fkInstitutionCourseId && this.courseOptions[uniqueKey] && this.courseOptions[uniqueKey].length)) {
          validDatas.push('fkInstitutionCourseId')
        }else {
          validDatas.push('institutionCourseName')
        }
        console.log(validDatas);
        for await (let item of validDatas){
          const valid = await new Promise(res=>{
            this.$refs.applicationForm[index].validateField(item,(error)=>{
              res(error)
            })
          })
          if(valid) error = true;
        }
        if(error) return;
        // 表单校验结束
        let other = data.infoJson ? data.infoJson.other : {}
        // 如果有一键申请
        if(data.hasOneClick && !data.errorTip ){
          // 获取一键申请提交的内容与动态表单内容（如有）
          const infoJson = this.$refs.oneClickFormRef[0].getSubmitValue()
          if((infoJson && !('other' in infoJson)) || (infoJson && Object.keys(infoJson).length > 1 && 'other' in infoJson)) {
            const item = data.item;
            if (item && item.length) {
              infoJson.other = {};
              item.map((val) => {
                if (val.fieldName === 'password' && data[val.fieldName]) {
                  infoJson.other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
                } else {
                  infoJson.other[val.fieldName] = data[val.fieldName] || '';
                }
              })
            }
            data.infoJson = JSON.stringify(infoJson);
          } else {
            // 一键申请相关没有选择完整则提示且不可提交
            this.$msge.error(this.t('applyCourse.currentSchoolCanOneClick'))
            return;
          }
        } else {
          // 无一键申请，获取动态表单内容（如有）
          const item = data.item;
          data.infoJson = '';
          if (item && item.length) {
            other = other || {}
            item.map((val) => {
              if (val.fieldName === 'password' && data[val.fieldName]) {
                other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
              } else {
                other[val.fieldName] = data[val.fieldName] || '';
              }
            })
            data.infoJson = JSON.stringify({other});
          }
        }
        this.loading = true;
        if(this.onlineFormId){
          data.onlineFormId=this.onlineFormId
        }
        const res = await saveOrUpdateCourse(data);
        this.switchEditStatus(uniqueKey);
        if (!instCourse.id) {
          instCourse.id = res.data.data;
          data.id = res.data.data;
        }
        instCourse.infoJson = data.infoJson ? JSON.parse(data.infoJson) : '';
        this.$emit('save-submit-application')
      } catch(e){
        console.log(e, 'eeeeee');
      }
    },
    // 单个更新/新增
    async handleSave(uniqueKey, index) {
      const instCourses = this.stuInstCourseFormData.instCourses
      let data = JSON.parse(JSON.stringify(instCourses[index]));
      // 表单校验
      try {
        let error = false;
        let validDatas = ['fkAreaCountryId', 'fkInstitutionId', 'fkMajorLevelId', 'openingTime','institutionCourseWebsite'];
        if(data.fkInstitutionCourseId || (!data.fkInstitutionCourseId && this.courseOptions[uniqueKey] && this.courseOptions[uniqueKey].length)) {
          validDatas.push('fkInstitutionCourseId')
        }else {
          validDatas.push('institutionCourseName')
        }
        for await (let item of validDatas){
          const valid = await new Promise(res=>{
            this.$refs.applicationForm[index].validateField(item,(error)=>{
              res(error)
            })
          })
          if(valid) error = true;
        }
        if(error) return;
        // 表单校验结束
        let other = data.infoJson ? data.infoJson.other : {}
        // 如果有一键申请
        if(data.hasOneClick && !data.errorTip ){
          // 获取一键申请提交的内容与动态表单内容（如有）
          const infoJson = this.$refs.oneClickFormRef[0].getSubmitValue()
          if((infoJson && !('other' in infoJson)) || (infoJson && Object.keys(infoJson).length > 1 && 'other' in infoJson)) {
            const item = data.item;
            if (item && item.length) {
              infoJson.other = {};
              item.map((val) => {
                if (val.fieldName === 'password' && data[val.fieldName]) {
                  infoJson.other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
                } else {
                  infoJson.other[val.fieldName] = data[val.fieldName] || '';
                }
              })
            }
            data.infoJson = JSON.stringify(infoJson);
          } else {
            // 一键申请相关没有选择完整则提示且不可提交
            this.$msge.error(this.t('applyCourse.currentSchoolCanOneClick'))
            return;
          }
        } else {
          // 无一键申请，获取动态表单内容（如有）
          const item = data.item;
          data.infoJson = '';
          if (item && item.length) {
            other = other || {}
            item.map((val) => {
              if (val.fieldName === 'password' && data[val.fieldName]) {
                other[val.fieldName] = JSBase64.encode(data[val.fieldName]);
              } else {
                other[val.fieldName] = data[val.fieldName] || '';
              }
            })
            data.infoJson = JSON.stringify({other});
          }
        }
        this.loading = true;
        if(this.onlineFormId){
          data.onlineFormId=this.onlineFormId
        }
        const res = await saveOrUpdateCourse(data);
        const backup = this.getEditBackupByKey(uniqueKey)
        this.switchEditStatus(uniqueKey);
        if (!instCourses[index].id) {
          instCourses[index].id = res.data.data;
          data.id = res.data.data;
        }
        instCourses[index].infoJson = data.infoJson ? JSON.parse(data.infoJson) : '';
        // 处理背景资料目标国家部分
        // 编辑之前备份的数据与当前所有课程数据做比对，确认是否变更了国家数据，如变更，则更新背景资料目标国家数据
        // （背景资料的目标国家与课程的国家一致）
        if(backup){
          // 如果更新后选择的学校国家没有了，需要删除目标国家
          if(
            backup.fkAreaCountryId && 
            instCourses.findIndex(item=> item.fkAreaCountryId == backup.fkAreaCountryId) < 0
          ){
            this.updateDestCtry({
              oldCountryId: backup.fkAreaCountryId,
              newCountryId: data.fkAreaCountryId
            });
          } else if (instCourses.filter(item=> item.fkAreaCountryId == data.fkAreaCountryId).length === 1 && data.fkAreaCountryId != backup.fkAreaCountryId) {
            this.getDestCtry({
              type: "add",
              countryId: data.fkAreaCountryId,
            });
          }
        } else {
          this.getDestCtry({
            type: "add",
            countryId: data.fkAreaCountryId,
          });
        }
        // 处理其他与课程相关数据
        let fkInstitutionName = '';
        const fkInstitution = this.institutionOptions[uniqueKey].find(jtem=> jtem.id == data.fkInstitutionId)
        fkInstitution && (fkInstitutionName = fkInstitution.name)
        let institutionCourse = '';
        if(data.fkInstitutionCourseId) {
          const institutionCourseName = this.courseOptions[uniqueKey].find(jtem=> jtem.id == data.fkInstitutionCourseId);
          institutionCourse = institutionCourseName? institutionCourseName.name: data.institutionCourseName
        } else {
          institutionCourse = data.institutionCourseName
        }
        if (instCourses.length === 1) {
          this.addCompletedStatus();
        }
        if ((backup && backup.fkAreaCountryId !== instCourses[index].fkAreaCountryId) || !backup) {
          this.$emit('update-apply'); // 重新获取数据
        }
        this.onFormStatusChanged();
        this.$msge({
          message: this.t("common.saveSucc"),
          type: "success",
        });
      } catch(e) {
        console.log(e);
      }
      this.loading = false;
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.stuInstCourseFormData.instCourses.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    // 课程等级下拉改变
    async handleChangeMajorLevel(course,index) {
      const { fkMajorLevelId, uniqueKey } = course;
      if(this.noNeedLinkForLevel.indexOf(fkMajorLevelId)!==-1){
        this.$refs['applicationForm'][index].clearValidate('institutionCourseWebsite')
      }
      // 清空上一次自定义下拉选项
      course.fkInstitutionCourseId = null;
      course.institutionCourseName = '';
      this.faculty = [];
      this.subjectArea = [];
      this.facultyCdet = [];
      this.cdetCrseName4 = [];
      this.cdetCrseName5 = [];
      this.facultyTitleName = '';
      this.subjectAreaTitleName = '';
      this.facultyCdetTitleName = '';
      this.cdetCrseName4TitleName = '';
      this.cdetCrseName5TitleName = '';
      
      course.errorTip = this.t('validate.noFormDataTip')
      // if (course.item && course.item.length) {
      //   for (let item of course.item) {
      //     this.$delete(course, item.fieldName);
      //   }
      //   this.$delete(course, "item");
      // }
      if (!fkMajorLevelId) {
        this.$set(this.courseOptions, uniqueKey, []);
        return;
      }
      this.loading = true;
      await this.getCourseSelect(course);
      this.loading = false;
    },
    // 获取课程，自定义下拉框，课程列表
    async getCourseSelect(course) {
      const { fkInstitutionId, fkMajorLevelId, fkAreaCountryId, uniqueKey } =
        course;
      if (!fkAreaCountryId || !fkInstitutionId || !fkMajorLevelId) return;
      if (
        !isNaN(Number(fkInstitutionId)) &&
        this.institutionOptions.length !== 0
      ) {
        // 课程列表
        let courseList = await this.getCourseOptions(fkInstitutionId, fkMajorLevelId, uniqueKey);
        // 缓存是否有课程
        if(courseList && courseList.length){
          course.hasCourseList = true;
        } else {
          course.hasCourseList = false;
        }
      }
    },
    goLink(href){
      /^https:\/\/|^http:\/\//.test(href) && window.open(href, "_blank")
    },
    onConfirmMatch(course){
      this.initCourse = course;
    },
    onFormStatusChanged(){
      let lng = this.stuInstCourseFormData.instCourses.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    },
    // 查看备注列表
    checkRemark(value){
      this.$alrt(value, this.t('common.checkRemark'), {
        confirmButtonText: this.t('common.confirm'),
      });
    },
    // 更新了数据
    updateEmit(){
      this.$emit('update', this.stuInstCourseFormData.instCourses.map(item=>{
        let fkInstitutionCourseName = '';
        let fkInstitutionName = '';
        let fkInstitutionShortName = '';
        const fkInstitution = this.institutionOptions[item.uniqueKey].find(jtem=> jtem.id == item.fkInstitutionId)
        fkInstitution && (fkInstitutionName = fkInstitution.name)
        fkInstitution && (fkInstitutionShortName = fkInstitution.shortName)
        const fkInstitutionCourse = this.courseOptions[item.uniqueKey].find(jtem=> jtem.id == item.fkInstitutionCourseId)
        fkInstitutionCourse && (fkInstitutionCourseName = fkInstitutionCourse.name)
        return Object.assign({fkInstitutionCourseName, fkInstitutionName, fkInstitutionShortName}, item)
      }))
    }
  },
};
</script>
<style lang="scss" scoped>
.go-to-btn{
  width: 40px;
  height: 26px;
  background: linear-gradient( 31deg, #00B696, #00DE6E);
  background: linear-gradient( 31deg, var(--form-color-success), var(--form-color-success-light));
  border-radius: 4px;
  color: white;
  line-height: 28px;
  margin-top: 3px;
  margin-right: -2px;
  cursor: pointer;
  text-align: center;
  transition: all .2s ease;
  
  display: inline-block;
  &:hover{
    opacity: .8;
  }
}
.course-link-route{
  padding: 12px;
  line-height: 16px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  color: #00B696;
  color: var(--form-color-success);
  text-align: left;
  font-size: 14px;
}
.apply-editing{
  background: #E5F9FA;
  overflow: hidden;
  background: var(--form-apply-background);
  &:hover{
    border-color: #E5F9FA;
    border-color: var(--form-apply-background);
  }
 
  .arrow-wrap{
    width: 180px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #7CBDC0;
    color: var(--form-apply-label);
    background-color: #D8F8FA;
    background-color: var(--form-apply-label-background);
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    position: relative;
    .icon {
      width: 124px;
      margin-bottom: 12px;
      transform: scaleX(-1);
      position: absolute;
      &::v-deep{
        path{
          fill: #7CBDC0;
          fill: var(--form-apply-label);
        }
      }
    }
    span{
      margin-top: 38px;
      font-size: 14px;
    }
  }
  .course-wrap{
    .course-left{
      transform: translate3d(100%, 0, 0);
      transition: transform .25s linear .25s;
      z-index: 10;
    }
    .course-center{
      opacity: 0;
      transition: opacity .25s linear;
    }
    
    &.active{
      align-items: center;
      padding-bottom: 12px;
      .course-left{
        transform: translate3d(0,0,0);
        transition: transform .25s linear;
      }
      .course-center{
        opacity: 1;
        transition: opacity .25s linear .25s;
      }
    }
  }
  .match-box{
    height: auto;
    min-height: 320px;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 48px;
    padding-bottom: 24px;
    .match-loading{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, .7);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-100%);
      opacity: 0;
      transition: opacity .25s ease;
      z-index: 12;
      span{
        color: #7CBDC0;
        color: var(--form-apply-label);
        font-size: 24px;
        display: inline-block;
        font-weight: bold;
        animation: match-loading 2s linear infinite;
      }
      &.loading{
        transform: translateY(0);
        opacity: 1;
      }
    }
    .match-result{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .level-list{
        width: 100%;
      }
      .dot{
        color: #ff9700;
        color: var(--form-color-primary);
      }
      .title{
        white-space: pre-line;
        color: #333;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
      .match-tip{
        font-size: 24px;
        color: #7CBDC0;
        color: var(--form-apply-label);
        font-size: 12px;
        text-align: center;
      }
    }
  }
  
}
.custom-course-label{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  .label{
    font-size: 14px;
  }
  .tip{
    color: #999999;
  }
  .btn{
    color:  #ff9700;;
    color: var(--form-color-primary);
    cursor: pointer;
    transition: color .25s ease;
    &:hover{
      color: #ffb64d;
      color: var(--form-color-primary-light);
    }
  }
}
.stu-all-form-info .el-form.disabled{
  .custom-course-label{
    .label{
      color: #aaa;
    }
  }
}
@media screen and(max-width: 768px){
  .website-input {
    /deep/ .el-input__inner {
      padding-right: 70px;
    }
  } 
  .arrow-wrap{
    margin-bottom: 12px;
  }
  .apply-editing{
    padding: 0 6px 6px;
    .course-wrap{
      flex-direction: column;
      .course-left{
        transform: translate3d(0,0,0);
      }
      .course-center{
        display: none;
      }
      &.active{
       
        .course-center{
          opacity: 1;
          display: flex;
        }
      }
    }
  }

}
</style>